import React from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Navbar, Nav, ListGroup, Card, Button, Row, Col, Breadcrumb } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Rule = () => {
  useTitle('利用規約・注意事項 | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>利用規約・注意事項</Breadcrumb.Item>
      </Breadcrumb>

      <h2 className="mt-3 text-2xl font-bold">***** 必ずお読みください *****</h2>

      <p className="mt-3">1.下記の限度額の範囲で保険金が給付されます。</p>
      <p>※保険の免責額及び給付される保険金を超える損害はご契約者様のご負担となります｡</p>
      <p>※保険の免責事項に該当する事故の場合、保険の給付はされません。また警察の事故証明がない場合、保険金の給付がされない場合があります。</p>
      <p>対人補償 1名につき無制限(自賠責保険を含む)</p>
      <p>対物補償 1事故につき1,000万円まで(免責額5万円)</p>
      <p>搭乗者傷害補償 1名につき500万円まで(死亡後遺障害のみ)※原付（125ccまで)を除く</p>
      <p>車両補償 1事故につkき時価額または当社が定める補償制度による支払いまで</p>
      <p>盗難補償 1事故につき当社が定める補償制度による支払いまで</p>
      <p>(鍵を抜かない、ハンドルロックを掛けないでの盗難につきましては補償の対象外となります。)</p>

      <p className="mt-3">2.運行中は本証を必ず携行し警察官並びに運輸局及び陸運支局の職員から要求があった場合は速やかに提示してください。</p>

      <p className="mt-3">3.運転中に万が一事故が発生したときは、法令の定める手続きの他、速やかに警察、当店、保険会社に連絡してください。</p>

      <p className="mt-3">4.ご契約書様以外の運転は補償及び賠償の対象とならず、ご契約者様の実費負担となります。</p>

      <p className="mt-3">5.利用期間中はご契約者様に車両の管理責任が発生します。第三者の過失有無を問わず、契約者様に損害の実費をご負担いただきます。</p>

      <p className="mt-3">6.利用期間中に駐車違反などの取締りを受けた場合、速やかに管轄の警察署に出頭し反則金などを納付してください。帰着時に納付書を、返却担当者に提示してください。</p>
      <p>ご返却時に反則金などのお支払いの完了が確認できない場合、放置駐車違反1件につき￥20,000の駐車違反金をご請求いたします。</p>
      <p>※違反処理も駐車違反金のお支払いにもご対応いただけない場合は、お客様の個人情報を含む違反の事実を警察・公安委員会に報告及び" 放置駐車違反にかかわる責任追及の為の必要な措置をとります。</p>
      <p>また、弊社『貸渡注意者リスト』への登録、以降の貸渡をお断り致します。</p>
      <p>※レンタルバイク返却後に警察署に出頭、反則金の支払いを完了し、交通違反告知書と領収印のある納付書・領収証書等を弊社にご提示いただく事により駐車違反金をご返金致します。</p>

      <p className="mt-3">7.各種テスト・サーキット走行など競技への利用や、海岸、河川敷または林間などの維持管理された道路以外の走行はできません。</p>

      <p className="mt-3">8.タイヤのパンクや損傷、劣悪な利用により生じた車体の損傷や腐食の補修費はご契約者様の実費負担となります。</p>

      <p className="mt-3">9.転倒損害が大きい場合は見積額をご請求させていただきます。バイク返却時に一時金をお預かりさせていただいてます。オプション車両補償に加入の場合は、バイク返却時に5万円をお預かりいたします。判断につきましては、バイクが戻って来てから当社で確認させていただき、返金が発生する場合は後日返金致します。</p>
      <p>免責額の判断基準は、外装のキズ等ですぐに次の貸し出しが出来る状態で当社に返却した場合、【2万円】</p>
      <p>外装の入れ替えや走行する為に修整が必要な箇所があった場合、車両としての価値がなくなった場合、【5万円】となります。尚、遅滞された場合は補償対象外になります。</p>

      <p className="mt-3">10.営業補償は車両補償加入の有無関係なしにかかります。基準は車両補償の免責内容と同じです。</p>

      <p className="mt-3">11.レッカーでの配送距離は180㎞以内となります。それ以上の距離はご契約者様の実費負担となります。</p>

      <p className="mt-3">12.レッカー移動に伴い発生した交通費及び宿泊費は、交通費【1万9千円】宿泊費【1万5千円】を超える場合は、契約者様の負担となります。</p>

      <p className="mt-3">13.延長をご希望の場合は電話連絡の上、延長開始日時までに来店して手続きをしていただく必要がございます。その際は、ご利用されている車両を必ずお持ちの上、ご契約者様がご来店ください。返却日当日でも返却時間を過ぎる場合は延長扱いになります。</p>

      <p className="mt-3">14.契約期間を無断で遅滞された場合、遅滞１日につきレンタル料金(24時間)の倍額をご請求させていただきます。</p>

      <p className="mt-3">15.契約期間を無断で遅滞して使用された場合の事故、その他貸渡約款の掲げる事項に違反があった場合は保険金が給付されません。</p>

      <p className="mt-3">16.当社の営業時間外(毎週月曜定休日)及びその他休業日の当社従業員立ち合いのない返却は、セキュリティーが作動するため固くお断りいたします。違反された場合は不法侵入で刑事告訴させて頂きます。</p>

      <p className="mt-3">17.返却期限日を過ぎても返却がない場合は、引き上げに同意し、異議申し立ては一切致しません。その際発生した、レッカー代等はご契約者様の負担となります。</p>

      <p className="mt-3">18.当社レンタル車両は全てご予約やその他予定で配車しております。ご契約のレンタル期日を守らず、何の連絡もなしに返却をして頂けない(1日でも)場合は即日警察に盗難届を出させていただきます。</p>

      <p className="mt-3">19.指定暴力団、指定暴力団関係団体の構成員または関係者、その他反社会的組織に該当しないことを確約します。</p>

      <p className="mt-3">20.事故（転倒含む）があった場合は発生した時点でレンタル契約は終了となります。レンタル料金はご返金できませんので予めご了承下さい。</p>

      <p className="mt-3">21.スマホスタンド、シガーソケット、ETCはあくまでもサービスでお付けしておりますのでスマホの落下等含む不具合に関しましては一切の責任を負いかねます。</p>

      <p className="mt-3">22.当社設定の走行距離の目安を超えて発生した車両の故障、トラブルに関しては借受人の責任とし、ロードサービスを含む修理費用は借受人負担となります。</p>

    </Container>

    <Footer />
    </>
  );
};

export default Rule;
