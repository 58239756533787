import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Alert, Card, Row, Col, Table, Button, ListGroup, Modal, Form } from 'react-bootstrap'
import { ValidationForm, SelectGroup } from 'react-bootstrap4-form-validation'
import { Shop, Calculator, Calendar as CalendarIcon, ShieldShaded, Bicycle, Pip, PersonLinesFill, CardHeading, CardText, XCircle, CheckCircle } from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';

import moment from 'moment';
import 'moment/locale/ja';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { ReservationCookie } from 'models/reservation';
import { getFetch } from 'api/fetch';
import { ReserveIndex as Response } from 'api/reserve';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Show = () => {
  useTitle('店舗・日時の選択 - 予約 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();
  const { sid } = useParams<Record<string, string | undefined>>()
  const { mid } = useParams<Record<string, string | undefined>>()
  const [cookies, setCookie] = useCookies(['reservation']);
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  const [show, setShow] = React.useState(false);
  const [startDate, setStartDate] = React.useState('');

  const [since1Days, setSince1Days] = React.useState('');
  const [since3Days, setSince3Days] = React.useState('');
  const [since1Weeks, setSince1Weeks] = React.useState('');
  const [since2Weeks, setSince2Weeks] = React.useState('');
  const [since1Months, setSince1Months] = React.useState('');

  const [disableSince1Days, setDisableSince1Days] = React.useState(false);
  const [disableSince3Days, setDisableSince3Days] = React.useState(false);
  const [disableSince1Weeks, setDisableSince1Weeks] = React.useState(false);
  const [disableSince2Weeks, setDisableSince2Weeks] = React.useState(false);
  const [disableSince1Months, setDisableSince1Months] = React.useState(false);

  const [reserveMinDay, setReserveMinDay] = React.useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  React.useEffect(() => {
   	// 月曜なら翌々日の水曜から予約可能
  	// 日曜の17以降なら再明後日の水曜から予約可能
	  // その以外は17時まで翌日から予約可能
    var reserveMinDay = 1;
    if (moment().day() === 0) {
      reserveMinDay = moment().hour() <= 17 ? 2 : 3;
    } else if (moment().day() === 1) {
      reserveMinDay = 2;
    } else if (moment().hour() > 17) {
      reserveMinDay = 2;
    }
    setReserveMinDay(reserveMinDay);

    if (!mid) {
      return;
    }

    getFetch(`/reserve/?sid=${sid}&mid=${mid}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, [mid]);

  const tileDisabled = (v: any) => {
    // 予約可能な日以外なら予約不可
    if (response?.enable_days && response?.enable_days[moment(v.date).format('YYYYMMDD')]) {
      return false;
    }

    return true;
  };

  const tileContent = (v: any) => {
    if (response?.enable_days && response?.enable_days[moment(v.date).format('YYYYMMDD')]) {
      return <CheckCircle className="text-success m-2" />
    }

    return <XCircle className="text-error m-2" />
  }

  const onClickDay = (value: any, event: any) => {
    setStartDate(moment(value).format('YYYYMMDD'));
    setSince1Days(moment(value).add(1, 'days').format('YYYY年MM月DD日 (ddd)'));
    setSince3Days(moment(value).add(3, 'days').format('YYYY年MM月DD日 (ddd)'));
    setSince1Weeks(moment(value).add(1, 'weeks').format('YYYY年MM月DD日 (ddd)'));
    setSince2Weeks(moment(value).add(2, 'weeks').format('YYYY年MM月DD日 (ddd)'));
    setSince1Months(moment(value).add(1, 'months').format('YYYY年MM月DD日 (ddd)'));

    // 月曜なら返却不可
    let disable = true;
    if (response?.enable_days && response?.enable_days[moment(value).format('YYYYMMDD')] && response?.enable_days[moment(value).format('YYYYMMDD')].includes(1)) {
      disable = false;
    }
    setDisableSince1Days(disable);

    disable = true;
    if (response?.enable_days && response?.enable_days[moment(value).format('YYYYMMDD')] && response?.enable_days[moment(value).format('YYYYMMDD')].includes(2)) {
      disable = false;
    }
    setDisableSince3Days(disable);

    disable = true;
    if (response?.enable_days && response?.enable_days[moment(value).format('YYYYMMDD')] && response?.enable_days[moment(value).format('YYYYMMDD')].includes(3)) {
      disable = false;
    }
    setDisableSince1Weeks(disable);

    disable = true;
    if (response?.enable_days && response?.enable_days[moment(value).format('YYYYMMDD')] && response?.enable_days[moment(value).format('YYYYMMDD')].includes(4)) {
      disable = false;
    }
    setDisableSince2Weeks(disable);

    disable = true;
    if (response?.enable_days && response?.enable_days[moment(value).format('YYYYMMDD')] && response?.enable_days[moment(value).format('YYYYMMDD')].includes(5)) {
      disable = false;
    }
    setDisableSince1Months(disable);

    setShow(true);

    return false;
  };

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();

    let endDate = '';
    const period = Number(values.period);
    switch (period) {
      case 1:
        endDate = moment(startDate, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD');
        break;
      case 2:
        endDate = moment(startDate, 'YYYYMMDD').add(3, 'days').format('YYYYMMDD');
        break;
      case 3:
        endDate = moment(startDate, 'YYYYMMDD').add(1, 'weeks').format('YYYYMMDD');
        break;
      case 4:
        endDate = moment(startDate, 'YYYYMMDD').add(2, 'weeks').format('YYYYMMDD');
        break;
      case 5:
        endDate = moment(startDate, 'YYYYMMDD').add(1, 'months').format('YYYYMMDD');
        break;
    }

    const reservationCookie: ReservationCookie = {
      start_date: startDate,
      start_time: String(values.start_time),
      end_date: endDate,
      period: period,
      shop_id: Number(sid),
      model_id: mid,
    };

    setCookie('reservation', JSON.stringify(reservationCookie), { path: '/' });
    history.push(`/reserve/shops/${sid}/models/${mid}/bikes`);
    return false;
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item>予約する</Breadcrumb.Item>
        <Breadcrumb.Item active>店舗・日時の選択</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-700 text-2xl text-red-700 font-bold pl-2">店舗・日時の選択</h1>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Shop size={20} className="d-inline mr-3" />
          <span className="align-middle">店舗</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">{GetEnumsName(Enums['shops']['id'], Number(sid))}</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Bicycle size={20} className="d-inline mr-3" />
          <span className="align-middle">車種</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">{response?.model.name}</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <PersonLinesFill size={20} className="d-inline mr-3" />
          <span className="align-middle">会員情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            お名前
            <span className="float-right">{response?.my.name1} {response?.my.name2}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            メールアドレス
            <span className="float-right">{response?.my.email}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CardHeading size={20} className="d-inline mr-3" />
          <span className="align-middle">勤務先情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            勤務先名
            <span className="float-right">{response?.my.work_place}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            勤務先住所
            <span className="float-right">{response?.my.work_address}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            勤務先電話番号
            <span className="float-right">{response?.my.work_tel}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CardText size={20} className="d-inline mr-3" />
          <span className="align-middle">その以外の連絡先情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            連絡先氏名
            <span className="float-right">{response?.my.other_name}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            連絡先住所
            <span className="float-right">{response?.my.other_address}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            連絡先電話番号
            <span className="float-right">{response?.my.other_tel}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Alert variant="info" className="mt-3">
        貸出日を選択してください。3か月先までの予約が可能です。
      </Alert>

      <div className="mt-3">
        <Calendar locale="ja-JP"
          minDate={moment().add(reserveMinDay, 'days').toDate()}
          maxDate={moment().add(3, 'months').toDate()}
          onClickDay={(value: any, event: any) => onClickDay(value, event)}
          tileDisabled={tileDisabled}
          tileContent={tileContent}
          className="mx-auto"
        />
      </div>

      <Alert variant="secondary" className="mt-3">
        <Alert.Heading>レンタル当日、予期せぬ車両トラブルについて</Alert.Heading>
        <p>（故障、事故）等で車両を変更していただく場合がございますので、ご了承ください。</p>
        <hr className="my-3" />
        <Alert.Heading>ご予約の受付締切は、ご利用になる日の前営業日の17時までとなります。</Alert.Heading>
        <p>（月曜は定休日となりますので、火曜のご予約の締切は前々日の日曜の17時までとなります）</p>
        <hr className="my-3" />
        <Alert.Heading>月曜日の貸し出し返却は一切できません</Alert.Heading>
        <p>月曜日の貸し出し返却は一切できません。ご返却期限が月曜日の場合前日までのご利用となります。</p>
      </Alert>
    </Container>
    <Footer />

    <Modal show={show} onHide={handleClose}>
      <ValidationForm onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>車両貸出日時の選択</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group required">
            <label className="form-label" htmlFor="start_time">貸出希望時間</label>
            <SelectGroup name="start_time" id="start_time"
              required
              errorMessage={{
                required: '貸出希望時間を選択してください',
              }}
            >
              <option value="1000">10時</option>
              <option value="1100">11時</option>
              <option value="1200">12時</option>
              <option value="1300">13時</option>
              <option value="1400">14時</option>
              <option value="1500">15時</option>
              <option value="1600">16時</option>
              <option value="1700">17時</option>
              <option value="1800">18時</option>
            </SelectGroup>
          </div>

          <div className="form-group required">
            <label className="form-label" htmlFor="period">貸出期間</label>
            <SelectGroup name="period" id="period"
              required
              errorMessage={{
                required: '貸出期間を選択してください',
              }}
            >
              <option value="">貸出期間を選択してください</option>
              <option value="1" disabled={disableSince1Days}>24時間 ({since1Days} まで)</option>
              <option value="2" disabled={disableSince3Days}>3日間 ({since3Days} まで)</option>
              <option value="3" disabled={disableSince1Weeks}>1週間 ({since1Weeks} まで)</option>
              <option value="4" disabled={disableSince2Weeks}>2週間 ({since2Weeks} まで)</option>
              <option value="5" disabled={disableSince1Months}>1ヶ月 ({since1Months} まで)</option>
            </SelectGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>キャンセル</Button>
          <Button variant="primary" type="submit"> 次へ </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
    </>
  );
};

export default Show;
