import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import {
  Container,
  Breadcrumb,
  Alert,
  Image,
  Table,
  ListGroup,
  Row,
  Col,
  Card,
  Button,
  Form,
} from 'react-bootstrap'
import { ValidationForm, TextInput, Radio } from 'react-bootstrap4-form-validation'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Complete = () => {
  useTitle('お問い合わせ | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">

      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item>お問い合わせ</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="w-100 rounded-lg">
        <Card.Header as="h5">お問い合わせ完了</Card.Header>
        <Card.Body>
          <Alert variant="success">
            お問い合わせを送信しました
          </Alert>

          <p className="mt-3">お問い合わせいただきありがとうございます。</p>
          <p>サポート担当者が内容を確認の上、</p>
          <p>メールにてご返答をお送りいたします。</p>
        </Card.Body>
      </Card>
    </Container>

    <Footer />
    </>
  );
};

export default Complete;
