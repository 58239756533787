import React from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Card, CardDeck, Nav, Row, Col, Table, Button, ListGroup, ListGroupItem, Image } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Index = () => {
  useTitle('ご利用案内 | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>ご利用案内</Breadcrumb.Item>
      </Breadcrumb>

      <Image src="/static/images/guide/barner.jpg" fluid className="my-3" />

      <Row>
        <Col className="text-center">
          <a href="#guide01"><Image src="/static/images/guide/01reserve.png" />ご予約</a>
        </Col>
        <Col className="text-center">
          <a href="#guide02"><Image src="/static/images/guide/02shop.png" fluid />ご来店</a>
        </Col>
        <Col className="text-center">
          <a href="#guide03"><Image src="/static/images/guide/03ride.png" fluid />ご利用</a>
        </Col>
        <Col className="text-center">
          <a href="#guide04"><Image src="/static/images/guide/04return.png" fluid />ご返却</a>
        </Col>
      </Row>

      <Card className="mt-3" id="guide01">
        <Card.Header>01.ご予約について</Card.Header>
        <Card.Body>
          <span className="font-bold border-l-2 border-red-500 pl-2">予約方法</span>
          <p>各車両ページより、スケジュールを確認しご予約ください。</p>
          <p className="mb-3">※18歳未満のお客様はご利用出来ません。</p>

          <span className="font-bold border-l-2 border-red-500 pl-2">予約可能時間について</span>
          <p className="mb-3">ご利用予定日90日前から前営業日17時まで</p>

          <span className="font-bold border-l-2 border-red-500 pl-2">お支払い方法について</span>
          <p className="mb-3">クレジットカード払い</p>

          <span className="font-bold border-l-2 border-red-500 pl-2">予約内容の変更について</span>
          <p className="mb-3">ご予約内容の変更、日程を変更する際は、<Link to="/inquiry" className="text-danger">「お問合せ」</Link>からご連絡の上、再度ご予約を行ってください。</p>

          <span className="font-bold border-l-2 border-red-500 pl-2">予約のキャンセルについて</span>
          <p>お問合せよりご連絡ください。キャンセル料は下記の通りです。</p>
          <p>また、キャンセル料のお支払いにつきましては別途店舗からご案内致します。</p>

          <p className="text-muted"><span className="mr-2">ご予約日の4日前</span>キャンセル料無料</p>
          <p className="text-muted"><span className="mr-2">ご予約の3日前～当日</span>レンタル料金の50％</p>
        </Card.Body>
      </Card>

      <Card className="mt-3" id="guide02">
        <Card.Header>02.ご来店</Card.Header>
        <Card.Body>
          <span className="font-bold border-l-2 border-red-500 pl-2">ご来店時の持ち物について</span>
          <p>ご来店は下記をお持ちください。オプションでヘルメットをお申込みでないお客様はヘルメットをお持ちください。</p>
          <ul className="mb-3">
            <li>・免許書</li>
            <li>・ヘルメット</li>
          </ul>

          <span className="font-bold border-l-2 border-red-500 pl-2">ご来店時間について</span>
          <p>ご予約日の10時から18時30分の間にお越しください。(手続きに時間がかかる為)</p>
          <p className="mb-3">ヤスカリはリバイクルK-JETが運営しております。ご来店の際はリバイクルK-JETのスタッフまでお声かけ下さい。</p>
        </Card.Body>
      </Card>

      <Card className="mt-3" id="guide03">
        <Card.Header>03.ご利用</Card.Header>
        <Card.Body>
          <span className="font-bold border-l-2 border-red-500 pl-2">契約者様以外のご利用</span>
          <p>本人以外の貸し出し、返却はお受けできませんので必ずご本人が時間内にご来店ください。</p>
          <p className="mb-3">ご契約者様以外の運転は不可です。(法人名でのお貸し出しの場合は可能ですのでご相談ください)</p>

          <span className="font-bold border-l-2 border-red-500 pl-2">駐車違反になった際</span>
          <p>『放置車両確認標章』が貼られたら、記載してある警察署に出頭して反則金をお支払い下さい。</p>
          <p>その後、必ず当店までお電話でご連絡をしていただき、バイク返却時に反則金の領収書をご提示ください。</p>
          <p className="mb-3">バイク返却時に反則金のお支払いが確認できない場合は駐車違反１件ごとに２万円お支払いしていただきます。</p>

          <span className="font-bold border-l-2 border-red-500 pl-2">走行距離の目安</span>
          <p>目安以上の距離を走行するためには整備が必要な場合があります。</p>
          <p>バイクを安全に乗るためには、オイル交換と整備が必要です。</p>
          <p className="mb-3">目安以上の距離を走行する場合、メンテナンスが必要な際にメンテナンスを怠り車両に故障や損害が発生した場合は、車両の時価額を請求いたします。</p>

          <Table responsive className="mb-3 text-nowrap">
            <thead>
              <tr>
                <th>クラス</th>
                <th>1日</th>
                <th>3日</th>
                <th>2週間</th>
                <th>1カ月</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>原付</td>
                <td>200km</td>
                <td>600km</td>
                <td>800km</td>
                <td>一度持ち込みで+800km</td>
              </tr>
              <tr>
                <td>125cc以下</td>
                <td>300km</td>
                <td>800km</td>
                <td>1000km</td>
                <td>一度持ち込みで+1000km</td>
              </tr>
              <tr>
                <td>126cc~</td>
                <td>500km</td>
                <td>1000km</td>
                <td>1500km</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>

          <span className="font-bold border-l-2 border-red-500 pl-2">走行中の不具合について</span>
          <p>営業時間内に契約店舗へご連絡ください。</p>
          <p className="mb-3">営業時間外に走行不可能になった場合は、ロードサービスをご利用ください。</p>

        </Card.Body>
      </Card>

      <Card className="mt-3" id="guide04">
        <Card.Header>04.ご返却</Card.Header>
        <Card.Body>
          <span className="font-bold border-l-2 border-red-500 pl-2">返却時間について</span>
          <p className="mb-3">ご返却日の10時から18時30分の間にお越しください。(手続きに時間がかかる為)</p>

          <span className="font-bold border-l-2 border-red-500 pl-2">車両の返却について</span>
          <p>返却時にガソリンが満タンでない場合は、当社規定の費用をいただきます。</p>
          <p>原付 3000円</p>
          <p>原付二種・ジャイロキャノピー 5000円</p>
          <p>それ以上 5000円</p>
        </Card.Body>
      </Card>

    </Container>

    <Footer />
    </>
  );
};

export default Index;
