import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Alert, Card, Button, Row, Col, Breadcrumb } from 'react-bootstrap'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';
import { useCookies } from 'react-cookie';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch, postFetch } from 'api/fetch';
import { Login as Response } from 'api/login';

export const Index = () => {
  useTitle('ログイン | 激安・便利なレンタルバイクのヤスカリ。');

  let history = useHistory();
  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('email', values.email);
    formData.append('password', values.password);

    postFetch('/login', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', 'メールアドレスもしくはパスワードが違います。', 3000);
        localStorage.clear();
        return;
      }

      localStorage.setItem('_member_name', `${res?.my.name1} ${res?.my.name2}`);
      localStorage.setItem('_member_id', res?.my.id);
      history.push('/my');
    });

    return false;
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>ログイン</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="justify-content-center">
        <Col md={6} lg={7} className="text-center">
          <Card className="w-100 rounded-lg">
            <Card.Body>
              <p>アカウントをお持ちでない方はこちら</p>
              <Button variant="danger" type="button" block className="mt-4" size="lg" as={Link} to="/register">新規会員登録</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center mt-4">
        <Col md={6} lg={7}>
          <Card className="w-100 rounded-lg">
            <Card.Body>
              <ValidationForm onSubmit={handleSubmit}>
                <div className="form-group required">
                  <TextInput name="email" id="email" type="email" placeholder="メールアドレスを入力してください"
                    required
                    maxLength={50}
                    errorMessage={{
                      type: 'メールアドレスを正しく入力してください',
                      required: 'メールアドレスを入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <TextInput name="password" id="password" type="password" placeholder="パスワードを入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: 'パスワードを入力してください',
                    }}
                  />
                </div>

                <Button variant="success" type="submit" block className="mt-4" size="lg">ログイン</Button>
              </ValidationForm>

              <Alert variant="light" className="mt-3"><Alert.Link as={Link} to="/password/forget" className="float-right px-0 text-danger">パスワードを忘れた方はこちら</Alert.Link></Alert>
            </Card.Body>
          </Card>
        </Col>
      </Row>


    </Container>

    <Footer />
    </>
  );
};

export default Index;
