import React from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, ListGroup, Badge } from 'react-bootstrap'
import NumericLabel from 'react-pretty-numbers';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { InformationsIndex as Response } from 'api/informations';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Index = () => {
  useTitle('新着情報一覧 | 激安・便利なレンタルバイクのヤスカリ。');

  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    getFetch(`/informations`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, []);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>新着情報</Breadcrumb.Item>
      </Breadcrumb>

      <ListGroup variant="flush">
        {(response && response.informations.length > 0) &&
          <>
          {response.informations.map(information => (
            <ListGroup.Item as={Link} to={`/informations/${information.id}`}>
              {information.title}
              <Badge className="float-right">{GetEnumsName(Enums['informations']['category'], information.category)}</Badge>
            </ListGroup.Item>
          ))}
          </>
        }
      </ListGroup>

    </Container>

    <Footer />
    </>
  );
};

export default Index;
