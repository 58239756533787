import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Alert, Card, Row, Col, Table, Button, ListGroup, Modal, Form } from 'react-bootstrap'
import { ValidationForm, SelectGroup } from 'react-bootstrap4-form-validation'
import { Shop, Calculator, Calendar as CalendarIcon, ShieldShaded, Bicycle, Pip, PersonLinesFill, CardHeading, CardText } from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';
import NumericLabel from 'react-pretty-numbers';
import { NotificationManager } from 'react-notifications';

import moment from 'moment';
import 'moment/locale/ja';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { ReservationCookie } from 'models/reservation';
import { getFetch } from 'api/fetch';
import { ReserveIndex as Response } from 'api/reserve';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

interface GoodsList { [key: string]: {mount: number, price: number} };

export const Options = () => {
  useTitle('オプション選択 - 予約 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();
  const { sid } = useParams<Record<string, string | undefined>>()
  const { mid } = useParams<Record<string, string | undefined>>()
  const [cookies, setCookie] = useCookies(['reservation']);

  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [amount, setAmount] = React.useState(0);
  const [optionsPrice, setOptionsPrice] = React.useState(0);
  const [goodsPrice, setGoodsPrice] = React.useState(0);
  const [startDate, setStartDate] = React.useState('');
  const [guarantee, setGuarantee] = React.useState(2);
  const [compensation, setCompensation] = React.useState(2);
  const [goods, setGoods] = React.useState<GoodsList>({});
  const [holidaysPrice, setHolidaysPrice] = React.useState(0);
  const [couponCode, setCouponCode] = React.useState('');
  const [couponDiscountAmount, setCouponDiscountAmount] = React.useState(0);

  const reservation = cookies.reservation;

  React.useEffect(() => {
    if (!mid) {
      return;
    }

    getFetch(`/reserve/options?sid=${sid}&mid=${mid}&start_date=${reservation.start_date}&period=${reservation.period}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
      setAmount(Number(res.model.price) + Number(res.holidays_price) + Number(res.model.guarantee) + Number(res.model.compensation));
      setHolidaysPrice(res.holidays_price);

      setOptionsPrice(Number(res.model.guarantee) + Number(res.model.compensation));
    });
  }, [mid]);

  const onGuaranteeChange = (v: number) => {
    if (v === 2) {
      setAmount(amount + Number(response?.model.guarantee));
      setOptionsPrice(optionsPrice + Number(response?.model.guarantee));
      setGuarantee(2);
    } else {
      setAmount(amount - Number(response?.model.guarantee));
      setOptionsPrice(optionsPrice - Number(response?.model.guarantee));
      setGuarantee(1);
    }
  };

  const onCompensationChange = (v: number) => {
    if (v === 2) {
      setAmount(amount + Number(response?.model.compensation));
      setOptionsPrice(optionsPrice + Number(response?.model.compensation));
      setCompensation(2);
    } else {
      setAmount(amount - Number(response?.model.compensation));
      setOptionsPrice(optionsPrice - Number(response?.model.compensation));
      setCompensation(1);
    }
  };

  const onGoodChange = (e: any, id: string, price: number) => {
    goods[id] = {
      mount: e.target.value,
      price: price,
    };

    let p = 0;

    setGoods(goods);
    for (const [key, value] of Object.entries(goods)) {
      p = p + value.mount * value.price;
    }
    setGoodsPrice(p);
    setAmount(Number(response?.model.price) + Number(response?.holidays_price) + optionsPrice + p - couponDiscountAmount);
  };

  const applyCoupon = () => {
    if (couponCode === '') {
      return false;
    }

    getFetch(`/coupons/?code=${couponCode}`).then(res => {
      if (!!res?.error) {
        setCouponDiscountAmount(0);
        setAmount(Number(response?.model.price) + Number(response?.holidays_price) + optionsPrice + goodsPrice);

        NotificationManager.error('', 'クーポン・コードが正しくありません', 3000);
        return false;
      }

      if (res.discount && res.discount > 0) {
        if (res.discount >= Number(response?.model.price)) {
          return false;
        }

        setCouponDiscountAmount(res.discount);
        setAmount(Number(response?.model.price) + Number(response?.holidays_price) + optionsPrice + goodsPrice - res.discount);
      }

    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    reservation.amount = amount;
    reservation.guarantee = guarantee;
    reservation.compensation = compensation;
    reservation.holidays_price = holidaysPrice;
    reservation.goods = {};
    if (couponCode !== '' && couponDiscountAmount > 0) {
      reservation.coupon_code = couponCode;
      reservation.coupon_discount_amount = couponDiscountAmount;
    }
  
    for (const [key, value] of Object.entries(goods)) {
      if (value.mount !== 0) {
        reservation.goods[key] = value.mount;
      }
    }

    setCookie('reservation', JSON.stringify(reservation), { path: '/' });

    history.push('/reserve/payment');

    return false;
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item>予約する</Breadcrumb.Item>
        <Breadcrumb.Item active>オプション選択</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-700 text-2xl text-red-700 font-bold pl-2">オプション選択</h1>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Shop size={20} className="d-inline mr-3" />
          <span className="align-middle">店舗</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">{GetEnumsName(Enums['shops']['id'], Number(sid))}</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Bicycle size={20} className="d-inline mr-3" />
          <span className="align-middle">車種</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">{response?.model.name}</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <PersonLinesFill size={20} className="d-inline mr-3" />
          <span className="align-middle">会員情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            お名前
            <span className="float-right">{response?.my.name1} {response?.my.name2}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            メールアドレス
            <span className="float-right">{response?.my.email}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CardHeading size={20} className="d-inline mr-3" />
          <span className="align-middle">勤務先情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            勤務先名
            <span className="float-right">{response?.my.work_place}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            勤務先住所
            <span className="float-right">{response?.my.work_address}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            勤務先電話番号
            <span className="float-right">{response?.my.work_tel}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CardText size={20} className="d-inline mr-3" />
          <span className="align-middle">その以外の連絡先情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            連絡先氏名
            <span className="float-right">{response?.my.other_name}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            連絡先住所
            <span className="float-right">{response?.my.other_address}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            連絡先電話番号
            <span className="float-right">{response?.my.other_tel}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CalendarIcon size={20} className="d-inline mr-3" />
          <span className="align-middle">貸出・返却日時</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            貸出希望日時
            <span className="float-right">{moment(reservation.start_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')} {reservation.start_time.substr(0, 2)}時</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            返却日時
            <span className="float-right">{moment(reservation.end_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')}  {reservation.start_time.substr(0, 2)}時</span>
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <ShieldShaded size={20} className="d-inline mr-3" />
          <span className="align-middle">補償オプションの選択</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            車両補償
            <div className="float-right">
              <Form.Control as="select" onChange={(e) => onGuaranteeChange(Number(e.target.value))} value={guarantee}>
                <option value="1">なし</option>
                <option value="2">あり</option>
              </Form.Control>
            </div>
            <span className="float-right mr-2 py-2"><NumericLabel>{response?.model.guarantee}</NumericLabel>円</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            盗難補償
            <div className="float-right">
              <Form.Control as="select" onChange={(e) => onCompensationChange(Number(e.target.value))} value={compensation}>
                <option value="1">なし</option>
                <option value="2">あり</option>
              </Form.Control>
            </div>
            <span className="float-right mr-2 py-2"><NumericLabel>{response?.model.compensation}</NumericLabel>円</span>
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Pip size={20} className="d-inline mr-3" />
          <span className="align-middle">用品オプションの選択</span>
        </Card.Header>
        <ListGroup variant="flush">
          {(response && response.goods.length > 0) &&
          <>
          {response.goods.map(good => (
            <ListGroup.Item as="li" className="align-middle" key={good.id}>
              {good.name}
              <div className="float-right">
                <Form.Control as="select" onChange={(e) => onGoodChange(e, good.id, good.price)}>
                  <option value="0">なし</option>
                  <option value="1">1個</option>
                  <option value="2">2個</option>
                </Form.Control>
              </div>
              <span className="float-right mr-2 py-2"><NumericLabel>{good.price}</NumericLabel>円</span>
            </ListGroup.Item>
          ))}
          </>
          }
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Pip size={20} className="d-inline mr-3" />
          <span className="align-middle">割引クーポン</span>
        </Card.Header>
        <ListGroup variant="flush">
            <ListGroup.Item as="li" className="align-middle">
              クーポン・コード
              <div className="float-right">
                <Form inline>
                  <Form.Control type="text" placeholder="クーポン・コード" onChange={(e: any) => setCouponCode(e.target.value)} />
                  <Button type="button" variant="outline-success" className="ml-1" onClick={applyCoupon}>適用</Button>
                </Form>
              </div>
            </ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Calculator size={20} className="d-inline mr-3" />
          <span className="align-middle">お見積り</span>
        </Card.Header>
        <ListGroup>
          <ListGroup.Item as="li">
            バイクレンタル料金
            <span className="float-right"><NumericLabel>{response?.model.price}</NumericLabel>円</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            用品オプション料金
            <span className="float-right"><NumericLabel>{goodsPrice}</NumericLabel>円</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            補償オプション料金
            <span className="float-right"><NumericLabel>{optionsPrice}</NumericLabel>円</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            ハイシーズン追加料金
            <span className="float-right"><NumericLabel>{holidaysPrice}</NumericLabel>円</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            クーポン割引額
            <span className="float-right">-<NumericLabel>{couponDiscountAmount}</NumericLabel>円</span>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="font-bold">
            レンタル料金合計（税込）
            <span className="float-right"><NumericLabel>{amount}</NumericLabel>円</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer>
          <small className="text-danger">※ ゴールデンウィーク、お盆休み、年末年始の期間は１日につき550円の追加料金を頂戴します。</small>
          <small className="d-block text-danger">※ お支払いはクレジットカード決済のみとなります。</small>
        </Card.Footer>
      </Card>

      <Button variant="primary" type="submit" size="lg" block className="mt-3" onClick={handleSubmit}>決済情報の入力へ</Button>

    </Container>
    <Footer />
    </>
  );
};

export default Options;
