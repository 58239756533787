import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications';

type Props = {
  className?: string,
}

export const LogoutButton: React.FC<Props> = (props) => {
  const history = useHistory();

  const handleClick = (e: any) => {
    localStorage.clear();
    NotificationManager.success('', 'ログアウトしました', 3000);
    history.push('/');
  };

  return (
    <Button variant="danger" size="lg" block className={props.className} onClick={handleClick}>ログアウト</Button>
  );
}
