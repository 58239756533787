import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import {
  Container,
  Breadcrumb,
  Card,
  CardDeck,
  Nav,
  Row,
  Col,
  Table,
  Button,
  Badge,
} from 'react-bootstrap'
import NumericLabel from 'react-pretty-numbers';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { ModelsIndex as Response } from 'api/models';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Index = () => {
  useTitle('車種・料金 | 激安・便利なレンタルバイクのヤスカリ。');
  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    getFetch(`/models${id ? `?shop=${id}` : ''}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, []);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        {id ?
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/shops' }}>店舗一覧</Breadcrumb.Item>
        :
          <></>
        }
        <Breadcrumb.Item active>{id ? `${GetEnumsName(Enums['shops']['id'], Number(id))}の` : ''}車種・料金</Breadcrumb.Item>
      </Breadcrumb>

      <Nav as="ul">
        {Object.entries(Enums['models']['class_id']).map(([key, value]) => (
          <Nav.Item as="li" className="col-lg-6 col-md-4">
            <Nav.Link as="a" href={`#${key}`} className="text-red-700">{value['ja']}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      {(response && response.classes.length > 0) &&
      response.classes.map(res => (
        <>
          <Table responsive striped borderless hover className="mt-3 text-nowrap">
            <tbody>
              <tr>
                <th colSpan={4} className="bg-red-700 text-white font-bold" id={`class${res.id}`}>{res.name}</th>
              </tr>
              <tr>
                <td className="align-middle font-bold">レンタル料金（税込）</td>
                <td className="align-middle">
                  <Table striped borderless hover size="sm">
                    <tbody>
                      <tr>
                        <td>24時間</td>
                        <td className="float-right"><NumericLabel>{res.price_1d}</NumericLabel>円</td>
                      </tr>
                      <tr>
                        <td>3日間</td>
                        <td className="float-right"><NumericLabel>{res.price_3d}</NumericLabel>円</td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td className="align-middle">
                  <Table striped borderless hover size="sm">
                    <tbody>
                      <tr>
                        <td>1週間</td>
                        <td className="float-right"><NumericLabel>{res.price_1w}</NumericLabel>円</td>
                      </tr>
                      <tr>
                        <td>2週間</td>
                        <td className="float-right"><NumericLabel>{res.price_2w}</NumericLabel>円</td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td className="align-middle">
                  <Table striped borderless hover size="sm">
                    <tbody>
                      <tr>
                        <td>1ヶ月</td>
                        <td className="float-right"><NumericLabel>{res.price_1m}</NumericLabel>円</td>
                      </tr>
                      <tr>
                        <td>遅延１日につき</td>
                        <td className="float-right"><NumericLabel>{res.price_plus_1d}</NumericLabel>円</td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>

          {(response && response.models.length > 0) &&
          <>
          <Row className="card-deck mt-3">
          {response.models.map(model => (
            <>
            {model.class_id === res.id &&
            <>
            <Col md={3}>
                <Card className="border-none text-center">
                  <Card.Img variant="top" src={`${STORAGE_URL}/models/${model.image ? model.image : 'noimage.png'}`} />
                  <Card.Body>
                    <Card.Title className="text-red-700"><Link className="stretched-link" to={`/models/${model.id}`}>{model.name}</Link></Card.Title>
                    <Card.Text>
                      <p>排気量：{model.displacement}cm3</p>
                      <p>シート高：{model.sheet}mm</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </>
            }
            </>
          ))}
          </Row>
          </>
        }
        </>
      ))
      }

      <Row>
        <Col md={6}>
          <Button className="bg-red-700 my-3" variant="danger" size="lg" block as={Link} to="/insurance">車両保険の内容</Button>
        </Col>
        <Col md={6}>
          <Button className="bg-red-700 my-3" variant="danger" size="lg" block as={Link} to="/options">オプション</Button>
        </Col>
      </Row>

    </Container>

    <Footer />
    </>
  );
};

export default Index;
