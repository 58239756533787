import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Navbar, Nav, ListGroup, Card, Button, Row, Col, Breadcrumb, Table } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { LogoutButton } from 'views/my/_logout_button';
import { getFetch } from 'api/fetch';
import { Member as Response } from 'models/member';
import { Editable } from 'views/_editable';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Profile = () => {
  useTitle('プロファイル | マイページ | 激安・便利なレンタルバイクのヤスカリ。');

  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    getFetch(`/my/profile`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, []);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/my' }}>マイページ</Breadcrumb.Item>
        <Breadcrumb.Item active>プロファイル</Breadcrumb.Item>
      </Breadcrumb>

      <Table responsive className="text-nowrap">
        <tbody>
          <tr>
            <th className="w-25">会員番号</th>
            <td>{response?.id}</td>
          </tr>
          <tr>
            <th>お名前</th>
            <td>{response?.name1} {response?.name2}</td>
          </tr>
        </tbody>
      </Table>

      <Nav fill variant="tabs" className="mt-3">
        <Nav.Item>
          <Nav.Link as={Link} to="/my">ご予約状況</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/my/profile" active>プロファイル</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/my/security">セキュリティ</Nav.Link>
        </Nav.Item>
      </Nav>

      <h3 className="font-weight-bold border-b-2 pb-3 mt-3">基本情報</h3>

      {(response) &&
        <Table responsive striped hover className="mt-3 text-nowrap">
          <tbody>
            <tr>
              <th className="w-25">会員番号</th>
              <td>
                {response.id}
              </td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>
                {response.email}
              </td>
            </tr>
          </tbody>
        </Table>
      }

      <h3 className="font-weight-bold border-b-2 pb-3 mt-3">詳細情報</h3>
      {(response) &&
        <Table responsive striped hover className="mt-3 text-nowrap">
          <tbody>
            <tr>
              <th className="w-25 py-4">お名前 (姓)</th>
              <td className="w-25">
                <Editable name="name1" value={response.name1} url={`/my/profile`} type="text" title="お名前 (姓)" required />
              </td>
              <th className="w-25 py-4">お名前 (名)</th>
              <td>
                <Editable name="name2" value={response.name2} url={`/my/profile`} type="text" title="お名前 (名)" required />
              </td>
            </tr>
            <tr>
              <th className="w-25 py-4">フリガナ (セイ)</th>
              <td className="w-25">
                <Editable name="kana1" value={response.kana1} url={`/my/profile`} type="text" title="フリガナ (セイ)" required />
              </td>
              <th className="w-25 py-4">フリガナ (メイ)</th>
              <td>
                <Editable name="kana2" value={response.kana2} url={`/my/profile`} type="text" title="フリガナ (メイ)" required />
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td colSpan={3} className="pl-4">
                {GetEnumsName(Enums['members']['sex'], response.sex)}
              </td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td colSpan={3} className="pl-4">
                {response.birth.replaceAll('-', '/')}
              </td>
            </tr>
            <tr>
              <th className="py-4">郵便番号</th>
              <td colSpan={3}>
                <Editable name="zip" value={response.zip} url={`/my/profile`} type="text" title="郵便番号" required />
              </td>
            </tr>
            <tr>
              <th className="py-4">住所 (市区町村まで)</th>
              <td colSpan={3}>
                <Editable name="address1" value={response.address1} url={`/my/profile`} type="text" title="住所" required />
              </td>
            </tr>
            <tr>
              <th className="py-4">番地等</th>
              <td colSpan={3}>
                <Editable name="address2" value={response.address2} url={`/my/profile`} type="text" title="番地等" required />
              </td>
            </tr>
            <tr>
              <th className="py-4">電話番号</th>
              <td colSpan={3}>
                <Editable name="tel" value={response.tel} url={`/my/profile`} type="text" title="電話番号" required />
              </td>
            </tr>
            <tr>
              <th className="py-4">免許番号</th>
              <td colSpan={3}>
                <Editable name="license" value={response.license} url={`/my/profile`} type="text" title="免許番号" required />
                <div className="mt-2" />
                <Editable name="license_file" value="" url={`/my/license_file`} type="image" title="免許写真" />
              </td>
            </tr>
          </tbody>
        </Table>
      }

      <h3 className="font-weight-bold border-b-2 pb-3 mt-3">勤務先情報</h3>
      {(response) &&
        <Table responsive striped hover className="mt-3 text-nowrap">
          <tbody>
              <tr>
                <th className="w-25 py-4">勤務先名</th>
                <td colSpan={3}>
                  <Editable name="work_place" value={response.work_place} url={`/my/profile`} type="text" title="勤務先名" />
                </td>
              </tr>
              <tr>
                <th className="py-4">勤務先住所</th>
                <td colSpan={3}>
                  <Editable name="work_address" value={response.work_address} url={`/my/profile`} type="text" title="勤務先住所" />
                </td>
              </tr>
              <tr>
                <th className="py-4">勤務先電話番号</th>
                <td colSpan={3}>
                  <Editable name="work_tel" value={response.work_tel} url={`/my/profile`} type="text" title="勤務先電話番号" />
                </td>
              </tr>
          </tbody>
        </Table>
      }

      <h3 className="font-weight-bold border-b-2 pb-3 mt-3">その以外の連絡先情報</h3>
      {(response) &&
        <Table responsive striped hover className="mt-3 text-nowrap">
          <tbody>
              <tr>
                <th className="w-25 py-4">連絡先氏名</th>
                <td colSpan={3}>
                  <Editable name="other_name" value={response.other_name} url={`/my/profile`} type="text" title="連絡先氏名" />
                </td>
              </tr>
              <tr>
                <th className="py-4">連絡先住所</th>
                <td colSpan={3}>
                  <Editable name="other_address" value={response.other_address} url={`/my/profile`} type="text" title="連絡先住所" />
                </td>
              </tr>
              <tr>
                <th className="py-4">連絡先電話番号</th>
                <td colSpan={3}>
                  <Editable name="other_tel" value={response.other_tel} url={`/my/profile`} type="text" title="連絡先電話番号" />
                </td>
              </tr>
          </tbody>
        </Table>
      }

      <LogoutButton className="mt-3 bg-red-700" />
    </Container>

    <Footer />
    </>
  );
};

export default Profile;
