import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Card, Alert, Badge, CardDeck, Nav, Row, Col, Table, Button, ListGroup } from 'react-bootstrap'
import { Shop, Gear } from 'react-bootstrap-icons';
import NumericLabel from 'react-pretty-numbers';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { PostsIndex as Response } from 'api/posts';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Index = () => {
  useTitle('ブログ一覧 | 激安・便利なレンタルバイクのヤスカリ。');

  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    getFetch(`/posts`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, []);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>ブログ</Breadcrumb.Item>
      </Breadcrumb>

      {(response && response.posts.length > 0) &&
        <>
        {response.posts.map(post => (
          <ListGroup.Item>
            <div className="media">
              <Link to={`/posts/${post.id}`}><img src={`${STORAGE_URL}/posts/${post.image}`} width="64" className="mr-3" /></Link>
              <div className="media-body">
                <h5 className="mt-0"><Link to={`/posts/${post.id}`}>{post.title}</Link></h5>
                <p className="mt-3 text-muted text-sm">{moment.unix(post.public_at).format('YYYY/MM/DD HH:mm')}</p>
              </div>
            </div>
          </ListGroup.Item>
        ))}
        </>
      }

    </Container>

    <Footer />
    </>
  );
};

export default Index;
