import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Card, CardDeck, Nav, Row, Col, Table, Button, ListGroup, Modal } from 'react-bootstrap'
import { Shop, Calculator, Calendar as CalendarIcon, ShieldShaded, Bicycle, Pip, PersonLinesFill, CardHeading, CardText } from 'react-bootstrap-icons';
import PayjpCheckout from 'react-payjp-checkout';
import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';
import { useCookies } from 'react-cookie';
import NumericLabel from 'react-pretty-numbers';

import moment from 'moment';
import 'moment/locale/ja';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { PAYJP_KEY, GetEnumsName, Enums } from 'config/const';
import { getFetch, postFetch } from 'api/fetch';
import { ReserveIndex as Response } from 'api/reserve';

export const Index = () => {
  useTitle('決済入力 - 予約 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(['reservation']);
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [token, setToken] = React.useState('');

  const [canSubmit, setCanSubmit] = React.useState(false);

  const [show, setShow] = React.useState(false);

  const reservation = cookies.reservation;

  const payjpCheckoutProps = {
    dataKey: PAYJP_KEY,
    dataText: 'クレジットカードで支払う',
    dataPartial: 'true',
    onCreatedHandler: (e: any) => {
      setToken(e.token);
      setCanSubmit(true);
    },
    onFailedHandler: (payload: any) => {
      NotificationManager.error('', payload.message, 3000);
    },
  }

  React.useEffect(() => {
    if (!reservation) {
      history.push('/models/');
    }

    getFetch(`/reserve/options?sid=${reservation.shop_id}&mid=${reservation.model_id}&start_date=${reservation.start_date}&period=${reservation.period}`).then(res => {
      if (!!res?.error) {
        return;
      }

      if (res.my?.role === 2) {
        setCanSubmit(true);
        setToken('411111111111111');
      }

      setResponse(res);
    });
  }, [reservation]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('token', token);
    formData.append('start_date', reservation.start_date);
    formData.append('start_time', reservation.start_time);
    formData.append('end_date', reservation.end_date);
    formData.append('period', reservation.period);
    formData.append('amount', reservation.amount);
    formData.append('shop_id', reservation.shop_id);
    formData.append('model_id', reservation.model_id);
    formData.append('bike_id', reservation.bike_id);
    formData.append('guarantee', reservation.guarantee);
    formData.append('compensation', reservation.compensation);
    formData.append('goods', JSON.stringify(reservation.goods));
    if (reservation?.coupon_code && reservation.coupon_code !== '' && reservation?.coupon_discount_amount && reservation.coupon_discount_amount > 0) {
      formData.append('coupon_code', reservation.coupon_code);
      formData.append('coupon_discount_amount', reservation.coupon_discount_amount);
    }

    postFetch('/reserve/payment', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', '予約失敗しました', 3000);
        return false;
      }
      NotificationManager.success('', '予約完了しました', 3000);
      history.push('/reserve/payment/complete');
    });

    return false;
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item href="">予約する</Breadcrumb.Item>
        <Breadcrumb.Item active>決済入力</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-700 text-2xl text-red-700 font-bold pl-2">決済入力</h1>

      <ValidationForm onSubmit={handleSubmit} className="mt-3">

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <Shop size={20} className="d-inline mr-3" />
            <span className="align-middle">店舗</span>
          </Card.Header>
          <ListGroup variant="flush">
          <ListGroup.Item as="li">{GetEnumsName(Enums['shops']['id'], Number(reservation?.shop_id))}</ListGroup.Item>
          </ListGroup>
        </Card>

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <Bicycle size={20} className="d-inline mr-3" />
            <span className="align-middle">車種</span>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as="li">{response?.model.name}</ListGroup.Item>
          </ListGroup>
        </Card>

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <PersonLinesFill size={20} className="d-inline mr-3" />
            <span className="align-middle">会員情報</span>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as="li">
              お名前
              <span className="float-right">{response?.my.name1} {response?.my.name2}</span>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              メールアドレス
              <span className="float-right">{response?.my.email}</span>
            </ListGroup.Item>
          </ListGroup>
          <Card.Footer className="text-center text-muted">
            <Link to="/my/profile">登録情報を変更する</Link>
          </Card.Footer>
        </Card>

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <CardHeading size={20} className="d-inline mr-3" />
            <span className="align-middle">勤務先情報</span>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as="li">
              勤務先名
              <span className="float-right">{response?.my.work_place}</span>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              勤務先住所
              <span className="float-right">{response?.my.work_address}</span>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              勤務先電話番号
              <span className="float-right">{response?.my.work_tel}</span>
            </ListGroup.Item>
          </ListGroup>
          <Card.Footer className="text-center text-muted">
            <Link to="/my/profile">登録情報を変更する</Link>
          </Card.Footer>
        </Card>

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <CardText size={20} className="d-inline mr-3" />
            <span className="align-middle">その以外の連絡先情報</span>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as="li">
              連絡先氏名
              <span className="float-right">{response?.my.other_name}</span>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              連絡先住所
              <span className="float-right">{response?.my.other_address}</span>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              連絡先電話番号
              <span className="float-right">{response?.my.other_tel}</span>
            </ListGroup.Item>
          </ListGroup>
          <Card.Footer className="text-center text-muted">
            <Link to="/my/profile">登録情報を変更する</Link>
          </Card.Footer>
        </Card>

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <CalendarIcon size={20} className="d-inline mr-3" />
            <span className="align-middle">貸出・返却日時</span>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as="li">
              貸出希望日時
              <span className="float-right">{moment(reservation?.start_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')} {reservation?.start_time.substr(0, 2)}時</span>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              返却日時
              <span className="float-right">{moment(reservation?.end_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')}  {reservation?.start_time.substr(0, 2)}時</span>
            </ListGroup.Item>
          </ListGroup>
        </Card>

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <Calculator size={20} className="d-inline mr-3" />
            <span className="align-middle">レンタル料金</span>
          </Card.Header>
          <ListGroup>
            <ListGroup.Item as="li" className="font-bold">
              合計（税込）
              <span className="float-right"><NumericLabel>{reservation?.amount}</NumericLabel>円</span>
            </ListGroup.Item>
          </ListGroup>
        </Card>

        <Card className="mt-3">
          <Card.Header className="text-red-700 font-bold">
            <Shop size={20} className="d-inline mr-3" />
            <span className="align-middle">クレジットカード情報入力</span>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as="li">
              <PayjpCheckout {...payjpCheckoutProps} />
            </ListGroup.Item>
          </ListGroup>
        </Card>

        <Row className="mt-4">
          <Col md={4}>
            <Button variant="secondary" block size="lg" onClick={(e) => history.push(`/models/${reservation.model_id}`)}>戻る</Button>
          </Col>
          <Col md={8}>
            <Button variant="primary" block size="lg" disabled={!canSubmit} onClick={() => setShow(true)}>確認する</Button>
          </Col>
        </Row>

      </ValidationForm>
    </Container>
    <Footer />


    <Modal show={show} animation={true} size="lg" dialogClassName="absolute bottom-0 inset-x-0">
      <Modal.Header closeButton>
        <Modal.Title>予約及びお支払いの確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped hover responsive>
          <tbody>
            <tr>
              <th className="w-25">店舗</th>
              <td>{GetEnumsName(Enums['shops']['id'], Number(reservation?.shop_id))}</td>
            </tr>
            <tr>
              <th>予約車種</th>
              <td>{response?.model.name}</td>
            </tr>
            <tr>
              <th>貸出希望日時</th>
              <td className="float-right">{moment(reservation?.start_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')} {reservation?.start_time.substr(0, 2)}時</td>
            </tr>
            <tr>
              <th>返却日時</th>
              <td className="float-right">{moment(reservation?.end_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')}  {reservation?.start_time.substr(0, 2)}時</td>
            </tr>
            <tr>
              <th>決済金額</th>
              <td className="float-right"><NumericLabel>{reservation?.amount}</NumericLabel>円</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>キャンセル</Button>
        <Button variant="primary" onClick={(e) => handleSubmit(e)}>決定する</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default Index;
