import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Navbar, Nav, ListGroup, Card, Button, Row, Col, Breadcrumb } from 'react-bootstrap'
import { ValidationForm, TextInput, Radio } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch, postFetch } from 'api/fetch';

export const Index = () => {
  useTitle('新規会員登録 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();

  React.useEffect(() => {
  }, []);

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('email', values.email);

    postFetch('/register', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', '会員登録失敗しました', 3000);
        return false;
      }

      history.push(`/register/auth?email=${values.email}`);
    });
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>会員登録</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="justify-content-center">
        <Col md={6} lg={7} className="text-center">
          <Card className="w-100 rounded-lg">
            <Card.Body>
              <p>アカウントをお持ち方はこちら</p>
              <Button variant="success" type="button" block className="mt-4" size="lg" as={Link} to="/login">ログイン</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center mt-3">
        <Col md={6} lg={7}>
          <Card className="w-100 rounded-lg">
            <Card.Header as="h5">新規会員登録</Card.Header>
            <Card.Body>
              <ValidationForm onSubmit={handleSubmit}>
                <div className="form-group required">
                  <label htmlFor="email">メールアドレス</label>
                  <TextInput name="email" id="email" type="email" placeholder="メールアドレスを入力してください"
                    required
                    maxLength={50}
                    errorMessage={{
                      type: 'メールアドレスを正しく入力してください',
                      required: 'メールアドレスを入力してください',
                    }}
                  />
                </div>

                <Button variant="danger" type="submit" block className="my-2" size="lg">新規会員登録</Button>
                <p className="mt-3">新規登録すると、<Link to="/rule" className="text-primary">利用規約</Link>及び<Link to="/privacy" className="text-primary">プライバシーポリシー</Link>に同意したとみなされます</p>
              </ValidationForm>

            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>

    <Footer />
    </>
  );
};

export default Index;
