import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Card, CardDeck, Nav, Row, Col, Table, Button, ListGroup } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications';
import { useCookies } from 'react-cookie';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch, postFetch } from 'api/fetch';

export const Complete = () => {
  useTitle('予約完了 - 予約 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(['reservation']);

  removeCookie('reservation', { path: '/' });

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>予約完了</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-500 text-2xl text-red-500 font-bold pl-2">予約完了</h1>

      <p className="mt-3">レンタルバイクご予約をいただきありがとうございました。</p>

    </Container>

    <Footer />
    </>
  );
};

export default Complete;
