import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Navbar, Nav, ListGroup, Card, Button, Row, Col, Breadcrumb, Table } from 'react-bootstrap'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { LogoutButton } from 'views/my/_logout_button';
import { getFetch, putFetch } from 'api/fetch';
import { Member as Response } from 'models/member';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Security = () => {
  useTitle('セキュリティ | マイページ | 激安・便利なレンタルバイクのヤスカリ。');
  const history = useHistory();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [password, setPassword] = React.useState('');

  React.useEffect(() => {
    getFetch(`/my/profile`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, []);

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('current_password', values.current_password);
    formData.append('password', values.password);

    putFetch('/my/password', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', 'パスワードが違います。', 3000);
        return false;
      }
      history.push('/my/profile')
      NotificationManager.success('', 'パスワード変更しました。', 3000);
    });
  };

  const matchPassword = (value: string) => {
    return value && value == password;
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/my' }}>マイページ</Breadcrumb.Item>
        <Breadcrumb.Item active>プロファイル</Breadcrumb.Item>
      </Breadcrumb>

      <Table responsive className="text-nowrap">
        <tbody>
          <tr>
            <th className="w-25">会員番号</th>
            <td>{response?.id}</td>
          </tr>
          <tr>
            <th>お名前</th>
            <td>{response?.name1} {response?.name2}</td>
          </tr>
        </tbody>
      </Table>

      <Nav fill variant="tabs" className="mt-3">
        <Nav.Item>
          <Nav.Link as={Link} to="/my">ご予約状況</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/my/profile">プロファイル</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/my/security" active>セキュリティ</Nav.Link>
        </Nav.Item>
      </Nav>

      {(response) &&
        <ValidationForm onSubmit={handleSubmit} className="mt-3">
          <div className="form-group required">
            <label htmlFor="current_password">現在のパスワード</label>
            <TextInput name="current_password" id="current_password" type="password" placeholder="現在のパスワードを入力してください"
              required
              minLength={8}
              maxLength={20}
              errorMessage={{
                required: '現在のパスワードを入力してください',
                minLength: '現在のパスワードを正しく入力してください'
              }}
            />
          </div>

          <div className="form-group required">
            <label htmlFor="password">新しいパスワード</label>
            <TextInput name="password" id="password" type="password" placeholder="新しいパスワードを入力してください"
              required
              onChange={(e: any) => setPassword(e.target.value)}
              minLength={8}
              maxLength={20}
              errorMessage={{
                required: '新しいパスワードを入力してください',
                minLength: '新しいパスワードを正しく入力してください'
              }}
            />
          </div>

          <div className="form-group required">
            <label htmlFor="password_confirm">新しいパスワード (確認用)</label>
            <TextInput name="password_confirm" id="password_confirm" type="password" placeholder="新しいパスワード(確認用)を入力してください"
              required
              validator={matchPassword}
              minLength={8}
              maxLength={20}
              errorMessage={{
                required: '新しいパスワードを入力してください',
                minLength: '新しいパスワードを正しく入力してください',
                validator: 'パスワードが一致しません'
              }}
            />
          </div>

          <Button variant="primary" type="submit" block className="my-2">パスワードを変更</Button>
        </ValidationForm>

      }

      <LogoutButton className="mt-3 bg-red-700" />
    </Container>

    <Footer />
    </>
  );
};

export default Security;
