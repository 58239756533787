import React from "react";
import { ValidationForm, TextInput, SelectGroup, Checkbox, Radio } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';
import NumericLabel from 'react-pretty-numbers';
import { Form, Modal, Image, ButtonGroup, Button, Card } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import { CardImage, Check, Dash } from 'react-bootstrap-icons';

//import ReactTagInput from '@pathofdev/react-tag-input';
//import '@pathofdev/react-tag-input/src/styles/index.scss';

import { GetEnumsName } from 'config/const';
import { putFetch } from 'api/fetch';

type Props = {
  name: string,
  value: any,
  url: string,
  type: string,
  title: string,
  className?: string,
  required?: boolean,
  minlength?: number,
  maxlength?: number,
  min?: number,
  max?: number,
  enums?: any,
  format?: string,
  width?: number,
  size?: string,
}

export const Editable: React.FC<Props> = (props) => {
  const [validated, setValidated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [underEdit, setUnderEdit] = React.useState(false);
  const [image, setImage] = React.useState({ preview: '', raw: '' });
  const [value, setValue] = React.useState(props.value);
  const type = props.type;
  const [modalShow, setModalShow] = React.useState(false);
  const [tags, setTags] = React.useState<string[]>(String(props.value).split(','));

  React.useEffect(() => {
    setLoading(false);
  }, [props.name]);

  const editToggle = (e: any) => {
    e.preventDefault();

    setUnderEdit(!underEdit);
  };

  const imageChange = (e: any) => {
    e.preventDefault();

    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }

    setUnderEdit(!underEdit);
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const formSubmit = (e: any, values: any) => {
    e.preventDefault();

    if (type !== 'image' && value === values[props.name]) {
//      setUnderEdit(false);
//      return;
    }

    setLoading(true)

    let formData = new FormData();
    formData.append('name', props.name);
    formData.append('type', props.type);

    if (type === 'image') {
      if (image) {
        formData.append('value', image.raw);
      }
    } else if (type === 'richtext') {
      formData.append('value', value);
    } else if (type === 'tags') {
      formData.append('value', tags.join(','));
    } else {
      formData.append('value', values[props.name]);
    }

    if (props.format) {
      formData.append('format', props.format);
    }

    putFetch(props.url, formData).then(res => {
      if (res?.error) {
        NotificationManager.error('', `${props.title}の保存が失敗ました`, 3000);
      } else {
        if (type === 'image') {
          setValue(image.preview);
        } else if (type === 'select') {
          setValue(Number(values[props.name]));
        } else if (type === 'richtext') {
          setModalShow(false);
        } else if (type === 'tags') {
          setTags(String(values[props.name]).split(','));
        } else {
          setValue(values[props.name]);
        }
        NotificationManager.success('', `${props.title}が変更されました`, 3000);
      }
      setUnderEdit(false);
      setLoading(false);
    });
  };

  return (
    <>
    <EditForm
      name={props.name}
      value={props.value}
      url={props.url}
      type={props.type}
      title={props.title}
      required={props.required}
      minlength={props.minlength}
      maxlength={props.maxlength}
      min={props.min}
      max={props.max}
      enums={props.enums}
      format={props.format}
      width={props.width}
      size={props.size}
    />

    {type === 'richtext' &&
      <Modal size="lg" show={modalShow}>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Editor
            initialValue={value}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'code'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | code | help'
            }}
            onEditorChange={(content, _) => setValue(content)}
          />
        </Modal.Body>

        <Modal.Footer>
          <ValidationForm className="form-inline" onValidSubmit={formSubmit}>
            <Button type="button" color="secondary" className="mr-3" onClick={() => setModalShow(false)}>キャンセル</Button>
            <Button color="primary">保存</Button>
          </ValidationForm>
        </Modal.Footer>
      </Modal>
    }
    </>
  );
};

const EditForm: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [underEdit, setUnderEdit] = React.useState(false);
  const [value, setValue] = React.useState(props.value);
  const [image, setImage] = React.useState({ preview: '', raw: '' });
  const type = props.type;
  const [tags, setTags] = React.useState<string[]>(String(props.value).split(','));
  const [modalShow, setModalShow] = React.useState(false);

  const imageChange = (e: any) => {
    e.preventDefault();

    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }

    setUnderEdit(!underEdit);
  };

  const editToggle = (e: any) => {
    e.preventDefault();

    setUnderEdit(!underEdit);
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const formSubmit = (e: any, values: any) => {
    e.preventDefault();

    if (type !== 'image' && value === values[props.name]) {
//      setUnderEdit(false);
//      return;
    }

    setLoading(true)

    let formData = new FormData();
    formData.append('name', props.name);
    formData.append('type', props.type);

    if (type === 'image') {
      if (image) {
        formData.append('value', image.raw);
      }
    } else if (type === 'richtext') {
      formData.append('value', value);
    } else if (type === 'tags') {
      formData.append('value', tags.join(','));
    } else {
      formData.append('value', values[props.name]);
    }

    if (props.format) {
      formData.append('format', props.format);
    }

    putFetch(props.url, formData).then(res => {
      if (res?.error) {
        NotificationManager.error('', `${props.title}の保存が失敗ました`, 3000);
      } else {
        if (type === 'image') {
          setValue(image.preview);
        } else if (type === 'select') {
          setValue(Number(values[props.name]));
        } else if (type === 'richtext') {
          setModalShow(false);
        } else if (type === 'tags') {
          setTags(String(values[props.name]).split(','));
        } else {
          setValue(values[props.name]);
        }
        NotificationManager.success('', `${props.title}が変更されました`, 3000);
      }
      setUnderEdit(false);
      setLoading(false);
    });
  };

  const SubmitButtons = () => {
    return (
      <ButtonGroup className="ml-2">
        <Button variant="secondary" onClick={editToggle} disabled={loading}><Dash size={20} /></Button>
        <Button type="submit" variant="primary" disabled={loading}><Check size={20} /></Button>
      </ButtonGroup>
    );
  }

  if (type === 'image') {
    if (!underEdit) {
      return (
        <Card className="text-center bg-light border border-sendoary">
          <label className="m-1">
            <input type="file" name={props.name} accept="image/*" className="d-none" onChange={(e) => imageChange(e)} />
            {value ?
              props.format == 'avatar' ?
              <Image src={value} />
              :
              <Image src={value} thumbnail />
            :
              <Card.Body>
                <div>{props.title ? props.title : '画像'}を更新する</div>
              </Card.Body>
            }
          </label>
        </Card>
      );
    } else {
      return (
        <>
        {image.preview && 
          props.format == 'avatar' ?
          <Image src={image.preview} />
          :
          <Image src={image.preview} thumbnail />
        }
        <ValidationForm className="form-inline mt-1" onSubmit={formSubmit}>
          <input type="hidden" name={props.name} value={image.preview} />
          <SubmitButtons />
        </ValidationForm>
        </>
      );
    }
  } else if (type === 'number') {
    if (!underEdit) {
      return (
        <div className="mt-2 editableContainer">
          <a href="/#" className="ml-3 text-danger" onClick={editToggle}><NumericLabel>{value}</NumericLabel></a>
        </div>
      );
    } else {
      return (
        <ValidationForm className="form-inline mt-1" onSubmit={formSubmit}>
          <TextInput name={props.name} type="number" value={value} onChange={handleChange} required={props.required} minLength={props.min} maxLength={props.max} />
          <SubmitButtons />
        </ValidationForm>
      );
    }
  } else if (type === 'select') {
    if (!underEdit) {
      return (
        <div className="mt-2 editableContainer">
          <a href="/#" className="ml-3 text-danger" onClick={editToggle}>{GetEnumsName(props.enums, value)}</a>
        </div>
      );
    } else {
      return (
        <ValidationForm className="form-inline mt-1" onSubmit={formSubmit}>
          <SelectGroup name={props.name} value={value} onChange={handleChange} required={props.required}>
            {Object.keys(props.enums).map((key) => {
              return <option value={props.enums[key]['id']}>{props.enums[key]['ja']}</option>
            })}
          </SelectGroup>

          <SubmitButtons />
        </ValidationForm>
      );
    }
  } else if (type === 'date') {
    if (!underEdit) {
      return (
        <div className="mt-3 editableContainer">
          <a href="/#" className="ml-3 text-danger" onClick={editToggle}>{value}</a>
        </div>
      );
    } else {
      return (
        <ValidationForm className="form-inline mt-1" onSubmit={formSubmit}>
          <TextInput name={props.name} type="date" value={value} onChange={handleChange} required={props.required} />
          <SubmitButtons />
        </ValidationForm>
      );
    }
  } else if (type === 'datetime') {
    if (!underEdit) {
      return (
        <div className="mt-3 editableContainer">
          <a href="/#" className="ml-3 text-danger" onClick={editToggle}>{moment.unix(value).format('YYYY/MM/DD HH:mm')}</a>
        </div>
      );
    } else {
      return (
        <ValidationForm className="form-inline mt-1" onSubmit={formSubmit}>
          <TextInput name={props.name} type="datetime-local" value={moment.unix(value).format('YYYY-MM-DDTHH:mm')} onChange={handleChange} required={props.required} />
          <SubmitButtons />
        </ValidationForm>
      );
    }
  } else if (type === 'tags') {
    if (!underEdit) {
      return (
        <div className="mt-3 editableContainer">
          <a href="/#" className="ml-3 text-danger" onClick={editToggle}>{value ? value : (props.title ? `${props.title}を編集` : '項目を編集')}</a>
        </div>
      );
    } else {
      // <ReactTagInput tags={tags} onChange={(value: any) => setTags(value)} />
      return (
        <ValidationForm className="form-inline mt-1" onSubmit={formSubmit}>
          <SubmitButtons />
        </ValidationForm>
      );
    }
  } else if (type === 'richtext') {
    if (!underEdit) {
      return (
        <div className="mt-1 p-1">
          <Button color="primary" className="ml-3" onClick={editToggle} block>{props.title ? `${props.title}を編集` : '項目を編集'}</Button>
        </div>
      );
    } else {
      return (
        <>
        <Editor
          value={value}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              'code'
            ],
            toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | code | help'
          }}
          onEditorChange={(content: any, _: any) => setValue(content)}
        />

        <ValidationForm className="form-inline mt-3" onSubmit={formSubmit}>
          <SubmitButtons />
        </ValidationForm>
        </>
      );
    }
  } else {
    if (!underEdit) {
      return (
        <div className="mt-2 editableContainer">
          <a href="/#" className="ml-3 text-danger" onClick={editToggle}>{value ? value : (props.title ? `${props.title}を編集` : '項目を編集')}</a>
        </div>
      );
    } else {
      return (
        <ValidationForm className="form-inline mt-1" onSubmit={formSubmit}>
          <TextInput name={props.name} type="text" value={value} onChange={handleChange} required={props.required} minLength={props.min} maxLength={props.max} />
          <SubmitButtons />
        </ValidationForm>
      );
    }
  }
};
