import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Alert, Card, Row, Col, Table, Button, ListGroup, Modal, Form, Badge } from 'react-bootstrap'
import { ValidationForm, SelectGroup } from 'react-bootstrap4-form-validation'
import { Shop, Calculator, Calendar as CalendarIcon, ShieldShaded, Bicycle, Pip, PersonLinesFill, CardHeading, CardText } from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';
import NumericLabel from 'react-pretty-numbers';
import { Radio, useRadioState } from 'pretty-checkbox-react';

import moment from 'moment';
import 'moment/locale/ja';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { ReservationCookie } from 'models/reservation';
import { getFetch } from 'api/fetch';
import { ReserveIndex as Response } from 'api/reserve';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';


export const Bikes = () => {
  useTitle('車両選択 - 予約 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();
  const bikeRadio = useRadioState();
  const { sid } = useParams<Record<string, string | undefined>>()
  const { mid } = useParams<Record<string, string | undefined>>()
  const [cookies, setCookie] = useCookies(['reservation']);
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  const reservation = cookies.reservation;

  React.useEffect(() => {
    if (!mid) {
      return;
    }

    getFetch(`/reserve/bikes?sid=${sid}&mid=${mid}&start_date=${reservation.start_date}&start_time=${reservation.start_time}&period=${reservation.period}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, [mid]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let bikeID = bikeRadio.state;

    reservation.bike_id = bikeID;
    setCookie('reservation', JSON.stringify(reservation), { path: '/' });

    history.push(`/reserve/shops/${sid}/models/${mid}/options`);

    return false;
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item>予約する</Breadcrumb.Item>
        <Breadcrumb.Item active>車両選択</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-700 text-2xl text-red-700 font-bold pl-2">車両選択</h1>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Shop size={20} className="d-inline mr-3" />
          <span className="align-middle">店舗</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">{GetEnumsName(Enums['shops']['id'], Number(sid))}</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Bicycle size={20} className="d-inline mr-3" />
          <span className="align-middle">車種</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">{response?.model.name}</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <PersonLinesFill size={20} className="d-inline mr-3" />
          <span className="align-middle">会員情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            お名前
            <span className="float-right">{response?.my.name1} {response?.my.name2}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            メールアドレス
            <span className="float-right">{response?.my.email}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CardHeading size={20} className="d-inline mr-3" />
          <span className="align-middle">勤務先情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            勤務先名
            <span className="float-right">{response?.my.work_place}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            勤務先住所
            <span className="float-right">{response?.my.work_address}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            勤務先電話番号
            <span className="float-right">{response?.my.work_tel}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CardText size={20} className="d-inline mr-3" />
          <span className="align-middle">その以外の連絡先情報</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            連絡先氏名
            <span className="float-right">{response?.my.other_name}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            連絡先住所
            <span className="float-right">{response?.my.other_address}</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            連絡先電話番号
            <span className="float-right">{response?.my.other_tel}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Footer className="text-center text-muted">
          <Link to="/my/profile">登録情報を変更する</Link>
        </Card.Footer>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <CalendarIcon size={20} className="d-inline mr-3" />
          <span className="align-middle">貸出・返却日時</span>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item as="li">
            貸出希望日時
            <span className="float-right">{moment(reservation.start_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')} {reservation.start_time.substr(0, 2)}時</span>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            返却日時
            <span className="float-right">{moment(reservation.end_date, 'YYYYMMDD').format('YYYY年MM月DD日 (ddd)')}  {reservation.start_time.substr(0, 2)}時</span>
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <ShieldShaded size={20} className="d-inline mr-3" />
          <span className="align-middle">車両の選択</span>
        </Card.Header>
        <ListGroup variant="flush">
        {(response && response.bikes.length > 0) ?
          response.bikes.map(bike => (
            <ListGroup.Item as="li" key={bike.id}>
              <Radio name="bike" color="success" value={bike.id} {...bikeRadio}>{bike.code}</Radio>
              {bike.tags.map(tag => (
                <Badge variant="danger" className="ml-1 p-1" key={tag}>{tag}</Badge>
              ))}
            </ListGroup.Item>
          ))
        :
          <ListGroup.Item as="li" className="text-danger">
            ＊下記に車両が出ない場合は車両が貸し出し中です。他の車両を選択してください。
          </ListGroup.Item>
        }
        </ListGroup>
      </Card>

      {(response && response.bikes.length > 0) ?
        <Button variant="primary" type="submit" size="lg" block className="mt-3" onClick={handleSubmit} disabled={!bikeRadio.state || bikeRadio.state === ''}>オプション選択へ</Button>
      :
        <Button variant="secondary" type="button" size="lg" block className="mt-3" onClick={() => history.push('/models')}>車種一覧へ</Button>
      }

    </Container>
    <Footer />
    </>
  );
};

export default Bikes;
