import React from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Table, Breadcrumb } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Law = () => {
  useTitle('特定商取引法に基づく表示 | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>特定商取引法に基づく表示</Breadcrumb.Item>
      </Breadcrumb>

      <Table striped bordered hover responsive className="text-nowrap mt-3">
        <tbody>
          <tr>
            <th>販売業者</th>
            <td className="font-bold">株式会社ケイジェット</td>
          </tr>
          <tr>
            <th>運営代表者</th>
            <td>金森　真佐樹</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>東京都足立区小台2-9-7　1階</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>03-5856-8075</td>
          </tr>
          <tr>
            <th>代金のお支払い方法</th>
            <td>クレジットカード</td>
          </tr>
          <tr>
            <th>販売価格</th>
            <td>各商品ページに基づく</td>
          </tr>
          <tr>
            <th>商品代金以外に必要な料金</th>
            <td>各種オプションや保険</td>
          </tr>
          <tr>
            <th>代金の支払時期</th>
            <td>決済会社の支払い時期に基づく</td>
          </tr>
          <tr>
            <th>新規予約</th>
            <td>ご利用前日の１７時まで（前日が休業日の場合、前営業日の１７時まで)</td>
          </tr>
          <tr>
            <th>予約変更</th>
            <td>ご利用前日の１７時まで (前日が休業日の場合、前営業日の１７時まで)</td>
          </tr>
          <tr>
            <th>キャンセルについて</th>
            <td>キャンセル料金について、ご予約日の４日前まではキャンセル料なし、<br />３日前から当日までのキャンセル料につきましては、50%のキャンセル料を頂戴いたします。</td>
          </tr>
        </tbody>
      </Table>

    </Container>

    <Footer />
    </>
  );
};

export default Law;
