import React from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Navbar, Nav, ListGroup, Card, Button, Row, Col, Breadcrumb } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Privacy = () => {
  useTitle('プライパシーポリシー | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>プライパシーポリシー</Breadcrumb.Item>
      </Breadcrumb>

      <h2 className="my-3 text-2xl font-bold">はじめに</h2>
      <p>ヤスカリ運営会社 株式会社ケイジェット（以下、「弊社」）は、各種法的サービスのご提供にあたり、お客様の個人情報をお預かりしております。</p>
      <p>弊社は個人情報を保護し、お客様に更なる信頼性と安心感をご提供できるように努めて参ります。</p>
      <p>弊社は、個人情報に関する法令を遵守し、個人情報の適切な取り扱いを実現致します。</p>


      <h2 className="mt-5 mb-3 text-2xl font-bold">個人情報の取得について</h2>
      <p>弊社は、偽りその他不正の手段によらず適正に個人情報を取得致します。</p>

      <h2 className="mt-5 mb-3 text-2xl font-bold">個人情報の利用について</h2>
      <p>弊社は、個人情報を以下の利用目的の達成に必要な範囲内で、利用致します。</p>
      <p>以下に定めのない目的で個人情報を利用する場合、あらかじめご本人の同意を得た上で行ないます。</p>

      <p className="mt-3">（1） お見積のご依頼・ご相談に対する回答及び資料送付</p>
      <p>（2） ご注文いただいた商品の発送</p>
      <p>（3） 各種商品・サービスに関する情報提供</p>

      <h2 className="mt-5 mb-3 text-2xl font-bold">個人情報の安全管理について</h2>
      <p>弊社は、取り扱う個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講じます。</p>

      <h2 className="mt-5 mb-3 text-2xl font-bold">個人情報の委託について</h2>
      <p>弊社は、個人情報の取り扱いの全部または一部を第三者に委託する場合は、当該第三者について厳正な調査を行い、取り扱いを委託された個人情報の安全管理が図られるよう当該第三者に対する必要かつ適切な監督を行います。</p>

      <h2 className="mt-5 mb-3 text-2xl font-bold">個人情報の第三者提供について</h2>
      <p>弊社は、個人情報保護法等の法令に定めのある場合を除き、個人情報をあらかじめご本人の同意を得ることなく、第三者に提供致しません。</p>

      <h2 className="mt-5 mb-3 text-2xl font-bold">個人情報の開示・訂正等について</h2>
      <p>弊社は、ご本人から自己の個人情報についての開示の請求がある場合、速やかに開示を致します。 その際、ご本人であることが確認できない場合には、開示に応じません。 個人情報の内容に誤りがあり、ご本人から訂正・追加・削除の請求がある場合、調査の上、速やかにこれらの請求に対応致します。</p>
      <p className="mt-3">その際、ご本人であることが確認できない場合には、これらの請求に応じません。 弊社の個人情報の取り扱いにつきまして、上記の請求・お問い合わせ等ございましたら、下記までご連絡くださいますようお願い申し上げます。</p>

      <p className="mt-3">【連絡先】株式会社ケイジェット 金森 真佐樹</p>
      <p>メール：info[at]rebikele.com <span className="text-muted">※[at]を@にして送信して下さい。</span></p>
      <p>電話：03-5856-8075</p>

      <h2 className="mt-5 mb-3 text-2xl font-bold">組織・体制</h2>
      <p>弊社は、金森 真佐樹 を個人情報管理責任者とし、個人情報の適正な管理及び継続的な改善を実施致します。</p>

      <h2 className="mt-5 mb-3 text-2xl font-bold">本方針の変更</h2>
      <p>本方針の内容は変更されることがあります。</p>
      <p>変更後の本方針については、弊社が別途定める場合を除いて、当サイトに掲載した時から効力を生じるものとします。</p>
    </Container>

    <Footer />
    </>
  );
};

export default Privacy;
