import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Card, CardDeck, Nav, Row, Col, Table, Button, ListGroup, ListGroupItem, Image } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Index = () => {
  useTitle('よくあるご質問 | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>よくあるご質問</Breadcrumb.Item>
      </Breadcrumb>

      <Image src="/static/images/faq/barner.jpg" fluid className="my-3" />

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />未成年の利用について</h3>
      <p>18歳からご利用いただけます。</p>
      <p>18歳未満のお客様が普通二輪免許を取得されていてもご利用いただけません。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />初心者の利用について</h3>
      <p>初心者でもご利用可能です。</p>
      <p>初心者ライダーの方々にも安心してご利用頂ける様、保険・保障を充実させております。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />レンタルはいつでも出来ますか？</h3>
      <p>月曜定休となりますので、月曜日以外のご利用が可能です。</p>
      <p>また、定休日には返却が出来かねますので日曜日迄にご返却頂けますようお願い致します。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />ヘルメットやグローブのレンタルは行ってますか？</h3>
      <p>ヘルメットやグローブのレンタルはレンタルする車両を選んで頂いた後にお選びいただけます。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />ETCカードの貸出はありますか？</h3>
      <p>ETCカードの貸し出しはございません。</p>
      <p>また車両によってはETC車載機がついていない車両もございます。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />三ノ輪店の利用方法について詳しく知りたい</h3>
      <p>三ノ輪店につきましたら国道4号線側入り口右手にインターホンがございますのでそちらを押して頂ければスタッフがご案内いたします。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />借りていた車両を別店舗にて返却出来ますか？</h3>
      <p>車両の返却はお借りいただいた店舗へご返却頂く必要がございます。</p>
      <p>三ノ輪店貸し出しの車両は三ノ輪店へ、足立店貸し出しの車両は足立店へご返却ください。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />時間外後の返却について</h3>
      <p>営業時間外はご対応できません。(営業時間：10：00～19：00)</p>
      <p>各店セキュリティがかかっておりますので後日改めてご返却ください。期日が過ぎている場合は延滞料がかかります。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />自家用車、バイク、自転車などで借りに行った場合車両は預かっていただけますか</h3>
      <p>上記の物含むすべての物のお預かりはできません。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />利用中バイクが故障した場合はどうしたらいいですか</h3>
      <p>貸し出し店舗より180キロ圏内でしたら無料で24時間ロードサービスが使えます。</p>
      <p>車両にトラブルが出た場合営業時間内であれば一度当店へお電話いただき、営業時間外でしたらそのままロードサービスをご手配ください。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />電話での問い合わせ、予約をしたいです。</h3>
      <p>当店はシステム上お電話でのご対応は致しておりません。お問い合わせ、ご予約はホームページよりお願いいたします。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />キャンセルしたいのですが、どうしたらよいですか</h3>
      <p><Link to="/guide" className="text-danger">こちら</Link>の「01.ご予約について」を参照ください。</p>

      <h3 className="font-bold pb-2 border-b-4 border-red-700 mb-2 mt-3"><img src="/static/images/faq/question.png" className="d-inline mr-2" width="18" />予約日時を変更したいのですが</h3>
      <p>予約日時を変更される際はお手数ですが、一度キャンセル頂き、再度予約を進めて頂きます様お願い致します。</p>
      <p>キャンセル方法につきましては、<Link to="/guide" className="text-danger">こちら</Link>の「01.ご予約について」を参照ください。</p>
    </Container>

    <Footer />
    </>
  );
};

export default Index;
