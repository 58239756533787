import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Container, Breadcrumb, Card, Alert, Badge, CardDeck, Nav, Row, Col, Table, Button, ListGroup } from 'react-bootstrap'
import { Shop, Gear } from 'react-bootstrap-icons';
import NumericLabel from 'react-pretty-numbers';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { Model as Response } from 'models/model';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Show = () => {
  const history = useHistory();
  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    if (!id) {
      return;
    }

    getFetch(`/models/${id}`).then(res => {
      if (!!res?.error) {
        return;
      }

      document.title = `${res.name} - 車種・料金 | 激安・便利なレンタルバイクのヤスカリ。`;
      setResponse(res);
    });
  }, [id]);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/models' }}>車種・料金</Breadcrumb.Item>
        <Breadcrumb.Item active>{response ? response.name : ''}</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-700 text-2xl text-red-700 font-bold pl-2">{response ? response.name : ''}</h1>

      <Alert variant="primary" className="mt-3">排気量：{response ? response.displacement : '-'}cm3 シート高：{response ? response.sheet : '-'}mm</Alert>

      {/*
      <div className="mt3">
        <Badge variant="secondary" className="mr-2 py-2">トップケース＋トップケースキャリア</Badge>
        <Badge variant="secondary" className="mr-2 py-2">ETC</Badge>
      </div>
      */}

      <Row className="mt-3">
        <Col md={6}>
          <img src={`${STORAGE_URL}/models/${response ? response.image : 'noimage.png'}`} />
        </Col>
        <Col md={6}>
          <h3 className="font-bold border-b-2 border-red-700 pb-2">レンタル料金（税込）</h3>

          <Table responsive className="mt-2 text-nowrap">
            <tbody>
              <tr>
                <th rowSpan={6} className="w-25 align-middle">基本料金</th>
              </tr>
              <tr>
                <td className="text-red-700">24時間</td>
                <td className="w-25 text-right"><NumericLabel>{response?.class ? response.class.price_1d : 0}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">3日間</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.price_3d : '0'}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">1週間</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.price_1w : '0'}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">2週間</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.price_2w : '0'}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">1ヶ月</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.price_1m : '0'}</NumericLabel>円</td>
              </tr>
            </tbody>
          </Table>

          <Table responsive className="mt-2 text-nowrap">
            <tbody>
              <tr>
                <th rowSpan={3} className="w-25 align-middle">追加料金</th>
              </tr>
              <tr>
                <td className="text-red-700">遅延１日につき</td>
                <td className="w-25 text-right"><NumericLabel>{response?.class ? response.class.price_plus_1d : '0'}</NumericLabel>円</td>
              </tr>
            </tbody>
          </Table>

          <Table responsive className="mt-2 text-nowrap">
            <tbody>
              <tr>
                <th rowSpan={6} className="w-25 align-middle">車両補償</th>
              </tr>
              <tr>
                <td className="text-red-700">24時間</td>
                <td className="w-25 text-right"><NumericLabel>{response?.class ? response.class.guarantee_1d : '0'}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">3日間</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.guarantee_3d : '0'}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">1週間</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.guarantee_1w : '0'}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">2週間</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.guarantee_2w : '0'}</NumericLabel>円</td>
              </tr>
              <tr>
                <td className="text-red-700">1ヶ月</td>
                <td className="text-right"><NumericLabel>{response?.class ? response.class.guarantee_1m : '0'}</NumericLabel>円</td>
              </tr>
            </tbody>
          </Table>

          <Table responsive className="mt-2 text-nowrap">
            <tbody>
              <tr>
                <th rowSpan={3} className="w-25 align-middle">盗難補償</th>
              </tr>
              <tr>
                <td className="text-red-700"></td>
                <td className="w-25 text-right"><NumericLabel>{response?.class ? response.class.compensation : '0'}</NumericLabel>円</td>
              </tr>
            </tbody>
          </Table>

          {response && response.shops && response.shops.length > 0 &&
          <Button variant="success" size="lg" block className="bg-green-700 mt-3" as={Link} to={`/reserve/models/${response.id}`}>
            この車種をレンタル予約する
          </Button>
          }

        </Col>
      </Row>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Gear size={20} className="d-inline mr-3" />
          <span className="align-middle">スペック</span>
        </Card.Header>
        <Card.Body>
          <Table borderless responsive hover striped className="text-nowrap">
            <tbody>
              <tr>
                <th className="w-1/6 text-red-700">必要免許</th>
                <td className="w-2/6">{(response && response.license) ? GetEnumsName(Enums['models']['license'], response.license) : '-'}</td>
                <th className="w-1/6 text-red-700">乗車定員</th>
                <td className="w-2/6">{response ? response.capacity : '-'}名</td>
              </tr>
              <tr>
                <th className="text-red-700">全長</th>
                <td><NumericLabel>{response ? response.length : '0'}</NumericLabel>mm</td>
                <th className="text-red-700">車両重量</th>
                <td><NumericLabel>{response ? response.weight : '0'}</NumericLabel>kg</td>
              </tr>
              <tr>
                <th className="text-red-700">全幅</th>
                <td><NumericLabel>{response ? response.width : '0'}</NumericLabel>mm</td>
                <th className="text-red-700">タンク容量</th>
                <td><NumericLabel>{response ? response.tank : '0'}</NumericLabel>L</td>
              </tr>
              <tr>
                <th className="text-red-700">全高</th>
                <td><NumericLabel>{response ? response.height : '0'}</NumericLabel>mm</td>
                <th className="text-red-700">使用燃料</th>
                <td>{response ? response.oil : '-'}</td>
              </tr>
              <tr>
                <th className="text-red-700">シート高</th>
                <td>{response ? response.sheet : '-'}mm</td>
                <th className="text-red-700">最高出力</th>
                <td>{response ? response.max_power : '-'}</td>
              </tr>
              <tr>
                <th className="text-red-700">排気量</th>
                <td><NumericLabel>{response ? response.displacement : '0'}</NumericLabel>cm3</td>
                <th className="text-red-700">最大トルク</th>
                <td>{response ? response.max_torque : '-'}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header className="text-red-700 font-bold">
          <Shop size={20} className="d-inline mr-3" />
          <span className="align-middle">取扱店舗</span>
        </Card.Header>
        <ListGroup variant="flush">
          {response && response.shops && response.shops.length > 0 &&
            <>
            {response.shops.map(shop_id => (
              <ListGroup.Item as={Link} to={`/shops/#shop${shop_id}`}>{GetEnumsName(Enums['shops']['id'], shop_id)}</ListGroup.Item>
            ))}
            </>
          }
        </ListGroup>
      </Card>

      {response && response.shops && response.shops.length > 0 &&
      <Button variant="success" size="lg" block className="bg-green-700 mt-3" as={Link} to={`/reserve/models/${response.id}`}>
        この車種をレンタル予約する
      </Button>
      }
    </Container>

    <Footer />
    </>
  );
};

export default Show;
