import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap'
import ScrollToTop from 'react-scroll-up';
import { ChevronDoubleUp, House, Shop, Bicycle, PersonFill } from 'react-bootstrap-icons';
import { NotificationContainer } from 'react-notifications';

import 'react-notifications/lib/notifications.css';

export const Footer = () => {
  const history = useHistory();

  return (
    <>
    <Container fluid="lg">
      <Row className="d-none d-md-block mt-5 mb-2 text-sm">
        <Col md={12} className="text-center">
          <Link to="/faq" className="mr-2">よくあるご質問</Link>
          <Link to="/privacy" className="mr-2">プライバシーポリシー</Link>
          <Link to="/law" className="mr-2">特定商取引法に基づく表示</Link>
          <Link to="/rule" className="mr-2">利用規約・注意事項</Link>
          <Link to="/inquiry" className="mr-2">お問い合わせ</Link>
        </Col>
        <Col md={12} className="mt-2 text-center text-muted">
          Copyright レンタルバイク『ヤスカリ』.
        </Col>
      </Row>
    </Container>

    <div className="d-block d-md-none">
      <div className="h-20" />
      <Navbar fixed="bottom" className="bg-red-700 text-white mx-0 px-0">
        <Row className="w-100 mx-0 px-0">
          <Col className="flex justify-center">
            <Link to="/"><House size={40} className="d-inline" /></Link>
          </Col>
          <Col>
            <Link to="/shops"><Shop size={40} className="d-inline" /></Link>
          </Col>
          <Col>
            <Link to="/models"><Bicycle size={40} className="d-inline" /></Link>
          </Col>
          <Col>
            <Link to="/my"><PersonFill size={40} className="d-inline" /></Link>
          </Col>
        </Row>
      </Navbar>
    </div>

    <ScrollToTop showUnder={160}>
      <ChevronDoubleUp size={32} className="text-danger" />
    </ScrollToTop>

    <NotificationContainer/>
    </>
  );
};
