import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Breadcrumb,
  Image,
  Alert,
} from 'react-bootstrap'
import { CardImage } from 'react-bootstrap-icons';
import { ValidationForm, TextInput, Radio, SelectGroup } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';
import zipcodeJa from 'zipcode-ja';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch, postFetch } from 'api/fetch';

export const Reset = () => {
  useTitle('パスワードをリセット | 激安・便利なレンタルバイクのヤスカリ。');
  const [password, setPassword] = React.useState('');

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const email = String(query.get('email'));

  if (email === undefined || email === '') {
    history.push('/password/forget');
  }

  React.useEffect(() => {
  }, []);

  const handleResetSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('email', email);
    formData.append('code', values.code);
    formData.append('password', values.password);

    postFetch('/password/reset', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', 'メールアドレス認証失敗しました', 3000);
        return false;
      }

      NotificationManager.success('', 'パスワードをリセットしました', 3000);
      history.push('/login');
    });
  };

  const matchPassword = (value: any) => {
    return value && value === password;
  }

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>パスワードをリセット</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="justify-content-center mt-3">
        <Col md={6} lg={7}>
          <Card className="w-100 rounded-lg">
            <Card.Body>
              <ValidationForm onSubmit={handleResetSubmit}>
                <div className="form-group">
                  <label htmlFor="email">メールアドレス</label>
                  <TextInput name="email" id="email" type="email" value={email}
                    disabled
                  />
                </div>

                <Alert variant="danger">
                  <p>上記メールアドレスに認証コードを送りました。</p>
                  <p>認証コードが届かない方は再度メールアドレスを入力してください。</p>
                </Alert>

                <div className="form-group required">
                  <label htmlFor="code">認証コード</label>
                  <TextInput name="code" id="code" type="text" placeholder="認証コードを入力してください"
                    required
                    minLength={6}
                    maxLength={6}
                    errorMessage={{
                      minLength: '認証コードを正しく入力してください',
                      required: '認証コードを入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="password">パスワード</label>
                  <TextInput name="password" type="password" placeholder="パスワードを入力してください"
                    required
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    minLength={8}
                    maxLength={20}
                    errorMessage={{
                      required: 'パスワードを入力してください',
                      minLength: 'パスワードを正しく入力してください'
                    }}
                  />
                  <p className="text-secondary">* 半角英数字8桁以上20桁以下を入力してください</p>
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="password">パスワード (確認用)</label>
                  <TextInput name="passwordConfirm" type="password" placeholder="パスワード (確認用)を入力してください"
                    required
                    minLength={8}
                    maxLength={20}
                    validator={matchPassword}
                    errorMessage={{
                      required: 'パスワードを入力してください',
                      minLength: 'パスワードを正しく入力してください',
                      validator: 'パスワードが一致しません'
                    }}
                  />
                </div>

                <Button variant="primary" type="submit" block className="my-2" size="lg">リセット</Button>
              </ValidationForm>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

    <Footer />
    </>
  );
};

export default Reset;
