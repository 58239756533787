import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Card, Form, Row, Col, Table, Button, ListGroup, Image } from 'react-bootstrap'
import { Shop, Gear } from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';

import { Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { ReserveModel as Response } from 'api/reserve';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Show = () => {
  useTitle('店舗・日時の選択 - 予約 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();
  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [shopID, setShopID] = React.useState(null);

  React.useEffect(() => {
    const memberID = localStorage.getItem('_member_id');
    if (!memberID) {
      history.push('/login/');
      return;
    }

    if (!id) {
      return;
    }

    getFetch(`/reserve/models/${id}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, [id]);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/models/${id}` }}>予約する</Breadcrumb.Item>
        <Breadcrumb.Item active>店舗・日時の選択</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="border-l-4	border-red-700 text-2xl text-red-700 font-bold pl-2">店舗・日時の選択</h1>

      <Row className="mt-3">
        <Col md={6}>
          <h3 className="text-center text-sm font-bold">{response ? response.model.name : ''}</h3>
          <Image fluid src={`${STORAGE_URL}/models/${response ? response.model.image : 'noimage.png'}`} />
        </Col>
        <Col md={6}>
          <h3 className="font-bold border-b-2 border-red-700 pb-2">店舗の選択</h3>

            <Form className="mt-3">
              <Form.Control as="select" onChange={(e: any) => setShopID(e.target.value)}>
                {response && response.shops && response.shops.length > 0 &&
                  <>
                  <option value="">店舗を選択してください</option>
                  {response.shops.map(shop_id => (
                    <option value={shop_id}>{GetEnumsName(Enums['shops']['id'], shop_id)}</option>
                  ))}
                  </>
                }
              </Form.Control>
          </Form>
          <p className="mt-3">三ノ輪店は無人店の為、詳しい操作の説明ができません。</p>
          <p>バイクの操作に不安のある方、日本語での説明に不安がある方は、足立小台本店の利用をお願いします。</p>
        </Col>
      </Row>

      <Button variant="primary" size="lg" type="submit" block className="mt-3" onClick={() => history.push(`/reserve/shops/${shopID}/models/${id}`)} disabled={!shopID}>
        車種の空き状況を見る
      </Button>

    </Container>

    <Footer />
    </>
  );
};

export default Show;
