import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Breadcrumb, Card, CardDeck, Nav, Row, Col, Table, Button, ListGroup, ListGroupItem, Image } from 'react-bootstrap'
import NumericLabel from 'react-pretty-numbers';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { GoodsIndex as Response } from 'api/goods';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Index = () => {
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    getFetch(`/goods`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, []);

  return (
    <>
    <Header />
    <Container fluid="lg">

      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>オプション</Breadcrumb.Item>
      </Breadcrumb>

      <Image src="/static/images/options/rentalitem.jpg" fluid className="my-3" />

      <Table responsive className="mb-3 text-nowrap">
        <thead>
          <tr>
            <th>レンタルオプション</th>
            <th>1日</th>
            <th>3日</th>
            <th>1週間</th>
            <th>2週間</th>
            <th>１ヶ月</th>
            <th>遅延１日につき</th>
          </tr>
        </thead>
        <tbody>
        {(response && response.goods.length > 0) &&
          response.goods.map(good => (
            <tr>
              <td>{good.name}</td>
              <td><NumericLabel>{good.price_1d}</NumericLabel>円</td>
              <td><NumericLabel>{good.price_3d}</NumericLabel>円</td>
              <td><NumericLabel>{good.price_1w}</NumericLabel>円</td>
              <td><NumericLabel>{good.price_2w}</NumericLabel>円</td>
              <td><NumericLabel>{good.price_1m}</NumericLabel>円</td>
              <td><NumericLabel>{good.price_plus_1d}</NumericLabel>円</td>
            </tr>
          ))
        }
        </tbody>
      </Table>

    </Container>

    <Footer />
    </>
  );
};

export default Index;
