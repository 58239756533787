import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Alert, Card, Button, Row, Col, Breadcrumb, Form } from 'react-bootstrap'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';
import { useCookies } from 'react-cookie';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch, postFetch } from 'api/fetch';

export const Forget = () => {
  useTitle('パスワードを忘れた方 | 激安・便利なレンタルバイクのヤスカリ。');

  let history = useHistory();

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('email', values.email);
    formData.append('birth', values.birth);

    postFetch('/password/forget', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', 'メールアドレスもしくは生年月日が違います。', 3000);
        return false;
      }

      history.push(`/password/reset?email=${values.email}`);
    });

    return false;
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>パスワードを忘れた方</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="justify-content-center mt-4">
        <Col md={6} lg={7}>
          <Card className="w-100 rounded-lg">
            <Card.Body>
              <ValidationForm onSubmit={handleSubmit}>

                <Alert variant="info" className="mb-3">
                  会員登録時の情報を入力してください。会員登録時のメールアドレスに、パスワード変更の認証コードを送信いたします。
                </Alert>

                <div className="form-group required">
                  <TextInput name="email" type="email" placeholder="メールアドレスを入力してください"
                    required
                    maxLength={50}
                    errorMessage={{
                      type: 'メールアドレスを正しく入力してください',
                      required: 'メールアドレスを入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <TextInput name="birth" type="date" placeholder="生年月日を入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: '生年月日を入力してください',
                    }}
                  />
                  <Form.Text className="text-muted">生年月日を入力してください</Form.Text>
                </div>

                <Button variant="success" type="submit" block className="mt-4" size="lg">この内容で送信する</Button>
              </ValidationForm>
            </Card.Body>
          </Card>
        </Col>
      </Row>


    </Container>

    <Footer />
    </>
  );
};

export default Forget;
