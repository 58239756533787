import { BASE_URL, API_URL } from 'config/const';

export const getFetch = async (endpoint: string): Promise<any> => {
  return await fetch(`${API_URL}${endpoint}`, {
    mode: 'cors',
    credentials: 'include',
    method: 'GET',
    headers: setHeaders(),
  })
  .then(res => checkStatus(res))
  .then(res => res.json())
  .then(res => {
    return res;
  })
  .catch(error => {
    return {
      error: error
    }
  })
}

export const postFetch = async (endpoint: string, formData: any): Promise<any> => {
  return await fetch(`${API_URL}${endpoint}`, {
    credentials: 'include',
    method: 'POST',
    headers: setHeaders(),
    body: formData
  })
  .then(res => checkStatus(res))
  .then(res => res.json())
  .then(res => {
    return res;
  })
  .catch(error => {
    return {
      error: error
    }
  })
}

export const putFetch = async (endpoint: string, formData: any): Promise<any> => {
  return await fetch(`${API_URL}${endpoint}`, {
    credentials: 'include',
    method: 'PUT',
    headers: setHeaders(),
    body: formData
  })
  .then(res => checkStatus(res))
  .then(res => res.json())
  .then(res => {
    return res;
  })
  .catch(error => {
    return {
      error: error
    }
  })
}

export const deleteFetch = async (endpoint: string): Promise<any> => {
  return await fetch(`${API_URL}${endpoint}`, {
    credentials: 'include',
    method: 'DELETE',
    headers: setHeaders(),
  })
  .then(res => checkStatus(res))
  .then(res => res.json())
  .then(res => {
    return res;
  })
  .catch(error => {
    return {
      error: error
    }
  })
}

const checkStatus = (res: any) => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 403) {
      localStorage.clear();
      window.location.href = `${BASE_URL}/login`;
    }

    // var error = new Error(res.status);
    var error = res.status;
    throw error;
  }
}

export const getFetchBlob = async (endpoint: string, fileName?: string): Promise<any> => {
  return await fetch(`${API_URL}${endpoint}`, {
    credentials: 'include',
    headers: setHeaders(),
  })
  .then(res => checkStatus(res))
  .then(res => res.blob())
  .then(res => {
    var url = window.URL.createObjectURL(res);
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName ? fileName : '';
    document.body.appendChild(a);
    a.click();    
    a.remove(); 
  })
  .catch(error => {
    return {
      error: error
    }
  })
}

const setHeaders = () => {
  var headers = {};
  const memberID = localStorage.getItem('_member_id');
  if (!memberID) {
    return headers;
  }

  headers = {
    'X-MEMBER-ID': memberID,
  };

  return headers;
};

