import React from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col, Dropdown } from 'react-bootstrap'
import { Calendar2Check, Key, List, PersonPlusFill, PersonFill, ShieldShaded } from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';

export const Header = () => {
  const history = useHistory();
  const [memberID, setMemberID] = React.useState<string | null>(null);
  const [memberName, setMemberName] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!memberID) {
      setMemberID(localStorage.getItem('_member_id'));
    }
    if (!memberName) {
      setMemberName(localStorage.getItem('_member_name'));
    }
    window.scrollTo(0, 0);
  }, [memberID, memberName]);

  return (
    <>
    <Container fluid="lg">
      <Navbar bg="white" className="px-0">
        <Navbar.Brand as={Link} to='/'>
          <img
            src="/static/images/logo/250x50.png"
            width="250"
            height="50"
            className="align-top d-none d-md-block"
            alt="ヤスカリ"
          />
          <span className="d-block d-md-none text-dark small float-right mt-3 ml-1">レンタルバイクのヤスカリ</span>
          <img
            src="/static/images/logo/300x300.jpg"
            width="50"
            height="50"
            className="align-top d-block d-md-none"
            alt="ヤスカリ"
          />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="d-none d-md-block text-sm text-right">
            <div className="text-danger">
              {(!memberID || !memberName) ?
                <>
                <PersonPlusFill size={20} className="d-inline" />
                <Link to="/register" className="ml-2 text-danger">会員登録</Link>
                </>
              :
                <>
                <PersonFill size={20} className="d-inline" />
                <Link to="/my" className="ml-2 text-danger">{memberName}</Link>
                </>
              }
            </div>
            <div className="mt-1">
              <span className="text-dark">激安・便利なレンタルバイクのヤスカリ</span>
            </div>
          </Navbar.Text>

          <Dropdown className="d-block d-md-none" alignRight={true}>
            <Dropdown.Toggle variant="none" id="dropdown-basic">
            <List size={40} className="d-inline" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
            {(memberName === '') ?
                <>
                <Dropdown.Item to="/login" as={Link}>ログイン</Dropdown.Item>
                <Dropdown.Item to="/register" as={Link}>会員登録</Dropdown.Item>
                </>
              :
                <>
                <Dropdown.Item to="/my" as={Link}>マイページ</Dropdown.Item>
                </>
              }

              <Dropdown.Divider />
              <Dropdown.Item to="/" as={Link}>ホーム</Dropdown.Item>
              <Dropdown.Item to="/guide" as={Link}>ご利用案内</Dropdown.Item>
              <Dropdown.Item to="/shops" as={Link}>店舗</Dropdown.Item>
              <Dropdown.Item to="/models" as={Link}>車種・料金</Dropdown.Item>
              <Dropdown.Item to="/insurance" as={Link}>保険と補償</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item to="/faq" as={Link}>よくあるご質問</Dropdown.Item>
              <Dropdown.Item to="/privacy" as={Link}>プライバシーポリシー</Dropdown.Item>
              <Dropdown.Item to="/law" as={Link}>特定商取引法に基づく表示</Dropdown.Item>
              <Dropdown.Item to="/rule" as={Link}>利用規約・注意事項</Dropdown.Item>
              <Dropdown.Item to="/inquiry" as={Link}>お問い合わせ</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>

      <div className="d-none d-md-block">
        <Nav justify fill variant="tabs" className="bg-red-700 h-14 pt-2">
          <Nav.Item className="">
            <Nav.Link to="/guide" as={Link} className="bg-red-700 text-white border-0">
              <ShieldShaded size={18} className="mr-2 d-inline" />
              ご利用案内
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link to="/shops" as={Link} className="bg-red-700 text-white border-0">店舗</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link to="/models" as={Link} className="bg-red-700 text-white border-0">
              <img src="/static/images/icons/motorcycle_w.png" width="26" className="mr-2 d-inline" />
              車種・料金
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link to="/insurance" as={Link} className="bg-red-700 text-white border-0">保険と補償</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link to="/faq" as={Link} className="bg-red-700 text-white border-0">よくあるご質問</Nav.Link>
          </Nav.Item>
          {/*
          <Nav.Item>
            <Nav.Link to="/" as={Link} className="bg-red-700 text-white border-0">
              <Calendar2Check size={18} className="mr-2 d-inline" />
              予約する
            </Nav.Link>
          </Nav.Item>
          */}
        </Nav>
      </div>
    </Container>
    </>
  );
};
