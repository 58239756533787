import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Breadcrumb,
  Image,
  Alert,
} from 'react-bootstrap'
import { CardImage } from 'react-bootstrap-icons';
import { ValidationForm, TextInput, Radio, SelectGroup } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';
import zipcodeJa from 'zipcode-ja';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch, postFetch } from 'api/fetch';

export const Auth = () => {
  useTitle('新規会員登録 | 激安・便利なレンタルバイクのヤスカリ。');

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const email = String(query.get('email'));
  const [showAuth, setShowAuth] = React.useState(true);
  const [showForm, setShowForm] = React.useState(false);
  const [sex, setSex] = React.useState('1');
  const [birth, setBirth] = React.useState('1980-01-01');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [enquetePurpose, setEnquetePurpose] = React.useState('');
  const [enqueteWant, setEnqueteWant] = React.useState('');
  const [enqueteTouring, setEnqueteTouring] = React.useState('');
  const [enqueteMagzine, setEnqueteMagzine] = React.useState('');
  const [enqueteChance, setEnqueteChance] = React.useState('');
  const [licenseFile, setLicenseFile] = React.useState({ preview: '', raw: '' });

  if (email === undefined || email === '') {
    history.push('/register');
  }

  React.useEffect(() => {
  }, []);

  const handleAuthSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('email', email);
    formData.append('code', values.code);

    postFetch('/register/auth', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', 'メールアドレス認証失敗しました', 3000);
        return false;
      }

      setShowAuth(false);
      setShowForm(true);
    });
  };


  const licenseFileChange = (e: any, image: string) => {
    e.preventDefault()

    if (e.target.files.length) {
      if (e.target.files[0].size >= 1048576) {
        setLicenseFile({
          preview: '',
          raw: ''
        });
        return;
      }

      setLicenseFile({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const validateLicenseFile = () => {
    return licenseFile.preview && licenseFile.preview !== '';
  }

  const handleInfoSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', values.password);
    formData.append('name1', values.name1);
    formData.append('name2', values.name2);
    formData.append('kana1', values.kana1);
    formData.append('kana2', values.kana2);
    formData.append('sex', sex);
    formData.append('tel', values.tel);
    formData.append('mobile', values.mobile);
    formData.append('birth', birth);
    formData.append('zip', values.zip);
    formData.append('address1', address1);
    formData.append('address2', address2);
    formData.append('license', values.license);
    formData.append('work_place', values.work_place);
    formData.append('work_address', values.work_address);
    formData.append('work_tel', values.work_tel);
    formData.append('other_name', values.other_name);
    formData.append('other_address', values.other_address);
    formData.append('other_tel', values.other_tel);
    formData.append('enquete_purpose', values.enquete_purpose);
    formData.append('enquete_want', values.enquete_want);
    formData.append('enquete_touring', values.enquete_touring);
    formData.append('enquete_magzine', values.enquete_magzine);
    formData.append('enquete_chance', values.enquete_chance);

    if (licenseFile) {
      formData.append('license_file', licenseFile.raw);
    }

    postFetch('/register/info', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', '会員登録ができません', 3000);
        return false;
      }

      NotificationManager.success('', '会員登録が完了しました', 3000);

      history.push('/login')
    });
  };

  const zipOnKeyUp = (e: any) => {
    let zip = e.target.value;
    if (zip.length !== 7) {
      return;
    }

    let zipJa = zipcodeJa[zip];
    if (zipJa === undefined || zipJa['address'].length < 3) {
      return;
    }

    setAddress1(`${zipJa['address'][0]}${zipJa['address'][1]}${zipJa['address'][2]}`);
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>会員情報入力認証</Breadcrumb.Item>
      </Breadcrumb>

      {(showAuth) &&
      <Row className="justify-content-center mt-3">
        <Col md={6} lg={7}>
          <Card className="w-100 rounded-lg">
            <Card.Body>
              <ValidationForm onSubmit={handleAuthSubmit}>
                <div className="form-group">
                  <label htmlFor="email">メールアドレス</label>
                  <TextInput name="email" id="email" type="email" value={email}
                    disabled
                  />
                </div>

                <Alert variant="danger">
                  <p>上記メールアドレスに認証コードを送りました。</p>
                  <p>認証コードが届かない方は再度メールアドレスを入力してください。</p>
                </Alert>

                <div className="form-group required">
                  <label htmlFor="code">認証コード</label>
                  <TextInput name="code" id="code" type="text" placeholder="認証コードを入力してください"
                    required
                    minLength={6}
                    maxLength={6}
                    errorMessage={{
                      minLength: '認証コードを正しく入力してください',
                      required: '認証コードを入力してください',
                    }}
                  />
                </div>

                <Button variant="primary" type="submit" block className="my-2" size="lg">確認</Button>
              </ValidationForm>

            </Card.Body>
          </Card>
        </Col>
      </Row>
      }

      {(showForm) &&
      <Row className="justify-content-center mt-3">
        <Col md={6} lg={7}>
          <Card className="w-100 rounded-lg">
            <Card.Body>
              <ValidationForm onSubmit={handleInfoSubmit}>
                <h3 className="font-weight-bold border-b-2 pb-3 mb-2">基本情報</h3>

                <div className="form-group">
                  <label htmlFor="email">メールアドレス</label>
                  <TextInput name="email" id="email" type="email" value={email}
                    disabled
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="password">パスワード</label>
                  <TextInput name="password" id="password" type="password" placeholder="パスワードを入力してください"
                    required
                    minLength={8}
                    maxLength={20}
                    errorMessage={{
                      required: 'パスワードを入力してください',
                      minLength: 'パスワードを正しく入力してください'
                    }}
                  />
                  <p className="text-secondary">* 半角英数字8桁以上20桁以下を入力してください</p>
                </div>

                <h3 className="font-weight-bold border-b-2 pb-3 my-3">お客様情報</h3>

                <div className="form-group required">
                  <label htmlFor="name">お名前</label>
                  <Row>
                    <Col md={6}>
                      <TextInput name="name1" id="name1" type="text" placeholder="姓"
                        required
                        maxLength={20}
                        errorMessage={{
                          required: '会員名を入力してください',
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <TextInput name="name2" id="name2" type="text" placeholder="名"
                        required
                        maxLength={20}
                        errorMessage={{
                          required: '会員名を入力してください',
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group required">
                  <label htmlFor="firstName">フリガナ</label>
                  <Row>
                    <Col md={6}>
                      <TextInput name="kana1" id="kana1" type="text" placeholder="セイ"
                        required
                        maxLength={20}
                        errorMessage={{
                          required: 'フリガナを入力してください',
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <TextInput name="kana2" id="kana2" type="text" placeholder="メイ"
                        required
                        maxLength={20}
                        errorMessage={{
                          required: 'フリガナを入力してください',
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="sex">性別</label>
                  <Radio.RadioGroup name="sex" required valueSelected={sex} onChange={(e: any, value: string) => setSex(value)}>
                      <Radio.RadioItem id="sex1" label="男性" value="1" />
                      <Radio.RadioItem id="sex2" label="女性" value="2" />
                  </Radio.RadioGroup>
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="birth">生年月日</label>
                  <TextInput name="birth" id="birth" type="date" placeholder="生年月日" value={birth} onChange={(e: any) => setBirth(e.target.value)}
                    required
                    errorMessage={{
                      required: '生年月日を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="zip">郵便番号</label>
                  <TextInput name="zip" id="zip" type="number" placeholder="例 0011234" onKeyUp={zipOnKeyUp}
                    required
                    maxLength={7}
                    errorMessage={{
                      required: '郵便番号を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="address1">住所</label>
                  <TextInput name="address1" id="address1" type="text" placeholder="住所(市区町村まで)を入力してください" value={address1} onChange={(e: any) => setAddress1(e.target.value)}
                    required
                    errorMessage={{
                      required: '住所を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="address2">番地等</label>
                  <TextInput name="address2" id="address2" type="text" placeholder="番地等を入力してください" value={address2} onChange={(e: any) => setAddress2(e.target.value)}
                    required
                    errorMessage={{
                      required: '番地等を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="mobile">携帯電話番号</label>
                  <TextInput name="mobile" id="mobile" type="mobile" placeholder="携帯電話番号を入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: '電話番号を入力してください',
                    }}
                  />
                </div>

                <div className="form-group mt-4">
                  <label htmlFor="tel">自宅電話番号</label>
                  <TextInput name="tel" id="tel" type="text" placeholder="自宅電話番号を入力してください"
                    maxLength={20}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="license">免許番号</label>
                  <TextInput name="license" id="license" type="license" placeholder="免許番号を入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: '免許番号を入力してください',
                    }}
                  />
                </div>

                <div className="form-group mt-4">
                  <label className="w-100 border-2" htmlFor="license_file">
                    {licenseFile.preview ?
                      <Image src={licenseFile.preview} thumbnail />
                    :
                      <Card.Body className="text-center">
                        <CardImage size={100} className="mx-auto" />
                        <span className="text-muted text-sm mt-3">免許画像をアップロードしてください</span>
                      </Card.Body>
                    }
                  </label>

                  <TextInput name="license_file" id="license_file" type="file" className="d-none" accept="image/*"
                    onChange={(e: any) => licenseFileChange(e, 'image')}
                    required
                    errorMessage={{
                      required: '免許画像をアップロードしてください',
                    }}
                  />
                  <p className="text-secondary"><span className="text-danger">*</span> 免許画像のファイルサイズを1MB以下にしてください</p>
                </div>

                <h3 className="font-weight-bold border-b-2 pb-3 my-4">勤務先情報</h3>
                <div className="form-group required mt-4">
                  <label htmlFor="work_place">勤務先名</label>
                  <TextInput name="work_place" id="work_place" type="text" placeholder="勤務先名を入力してください"
                    required
                    maxLength={50}
                    errorMessage={{
                      required: '勤務先名を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="work_address">勤務先住所</label>
                  <TextInput name="work_address" id="work_address" type="text" placeholder="勤務先住所を入力してください"
                    required
                    maxLength={100}
                    errorMessage={{
                      required: '勤務先住所を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="work_tel">勤務先電話番号</label>
                  <TextInput name="work_tel" id="work_tel" type="text" placeholder="勤務先電話番号を入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: '勤務先電話番号を入力してください',
                    }}
                  />
                </div>

                <h3 className="font-weight-bold border-b-2 pb-3 my-4">その以外の連絡先情報</h3>
                <div className="form-group required mt-4">
                  <label htmlFor="other_name">連絡先氏名</label>
                  <TextInput name="other_name" id="other_name" type="text" placeholder="連絡先氏名を入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: '連絡先氏名を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="other_address">連絡先住所</label>
                  <TextInput name="other_address" id="other_address" type="text" placeholder="連絡先住所を入力してください"
                    required
                    maxLength={100}
                    errorMessage={{
                      required: '連絡先住所を入力してください',
                    }}
                  />
                </div>

                <div className="form-group required mt-4">
                  <label htmlFor="other_tel">連絡先電話番号</label>
                  <TextInput name="other_tel" id="other_tel" type="text" placeholder="連絡先電話番号を入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: '連絡先電話番号を入力してください',
                    }}
                  />
                </div>

                <h3 className="font-weight-bold border-b-2 pb-3 my-4">アンケートご協力のお願い</h3>
                <div className="form-group required mt-4">
                  <label htmlFor="enquete_purpose">利用目的はなんですか</label>
                  <SelectGroup name="enquete_purpose" id="enquete_purpose"
                    value={enquetePurpose}
                    required
                    errorMessage="選択してください"
                    onChange={(e: any, value: string) => setEnquetePurpose(value)}
                  >
                    <option value="">選択してください</option>
                    <option value="1">旅行・レジャー</option>
                    <option value="2">仕事</option>
                    <option value="3">バイクの練習</option>
                    <option value="4">バイクの練習の修理中</option>
                    <option value="5">その他</option>
                  </SelectGroup>
                </div>

                <div className="form-group mt-3 required">
                  <label htmlFor="enquete_want">バイクは欲しいですか</label>
                  <SelectGroup name="enquete_want" id="enquete_want"
                    value={enqueteWant}
                    required
                    errorMessage="選択してください"
                    onChange={(e: any, value: string) => setEnqueteWant(value)}
                  >
                    <option value="">選択してください</option>
                    <option value="1">所有している</option>
                    <option value="2">購入している</option>
                    <option value="3">欲しい</option>
                    <option value="4">欲しくない</option>
                  </SelectGroup>
                </div>

                <div className="form-group mt-3 required">
                  <label htmlFor="enquete_touring">ツーリングに興味がある</label>
                  <SelectGroup name="enquete_touring" id="enquete_touring"
                    value={enqueteTouring}
                    required
                    errorMessage="選択してください"
                    onChange={(e: any, value: string) => setEnqueteTouring(value)}
                  >
                    <option value="">選択してください</option>
                    <option value="1">ある</option>
                    <option value="2">ない</option>
                  </SelectGroup>
                </div>

                <div className="form-group mt-3 required">
                  <label htmlFor="enquete_magzine">当店の案内を希望しますか</label>
                  <SelectGroup name="enquete_magzine" id="enquete_magzine"
                    value={enqueteMagzine}
                    required
                    errorMessage="選択してください"
                    onChange={(e: any, value: string) => setEnqueteMagzine(value)}
                  >
                    <option value="">選択してください</option>
                    <option value="1">はい</option>
                    <option value="2">いいえ</option>
                  </SelectGroup>
                </div>

                <div className="form-group mt-3 required">
                  <label htmlFor="enquete_chance">当店を知ったきっかけはなんですか</label>
                  <SelectGroup name="enquete_chance" id="enquete_chance"
                    value={enqueteChance}
                    required
                    errorMessage="選択してください"
                    onChange={(e: any, value: string) => setEnqueteChance(value)}
                  >
                    <option value="">選択してください</option>
                    <option value="1">知人の紹介</option>
                    <option value="2">ネットで検索</option>
                    <option value="3">通りすがり</option>
                    <option value="4">リピーター</option>
                    <option value="5">その他</option>
                  </SelectGroup>
                </div>

                <Button variant="primary" type="submit" block className="mt-4" size="lg">新規会員登録</Button>
              </ValidationForm>

            </Card.Body>
          </Card>
        </Col>
      </Row>
      }

    </Container>

    <Footer />
    </>
  );
};

export default Auth;
