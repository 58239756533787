import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Container, Breadcrumb, Card, Alert, Badge, CardDeck, Nav, Row, Col, Table, Button, ListGroup } from 'react-bootstrap'
import { Shop, Gear } from 'react-bootstrap-icons';
import NumericLabel from 'react-pretty-numbers';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { Post as Response } from 'models/post';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Show = () => {
  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    if (!id) {
      return;
    }

    getFetch(`/posts/${id}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
      document.title = `${res.title} | ブログ | 激安・便利なレンタルバイクのヤスカリ。`;
    });
  }, [id]);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/posts' }}>ブログ</Breadcrumb.Item>
        <Breadcrumb.Item active>{response ? response.title : ''}</Breadcrumb.Item>
      </Breadcrumb>

      <Alert variant="primary" className="mt-3">{response ? GetEnumsName(Enums['posts']['category'], response.category) : ''}</Alert>

      <Card>
        <Card.Img variant="top" src={`${STORAGE_URL}/posts/${response ? response.image : 'noimage.png'}`} />
        <Card.Body>
          <Card.Title>{response ? response.title : ''}</Card.Title>
          <Card.Text>
            {response ? ReactHtmlParser(response.content) : ''}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <span className="text-muted float-right">{response ? moment.unix(response.public_at).format('YYYY/MM/DD HH:mm') : ''}</span>
        </Card.Footer>
      </Card>
    </Container>

    <Footer />
    </>
  );
};

export default Show;
