import React from 'react';
import { useTitle } from 'react-use';
import { Container, Breadcrumb, Alert, Image, Table, ListGroup, Row, Col } from 'react-bootstrap'

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Index = () => {
  useTitle('車両保険の内容について | 激安・便利なレンタルバイクのヤスカリ。');

  return (
    <>
    <Header />
    <Container fluid="lg">

      <Breadcrumb className="mt-3">
        <Breadcrumb.Item href="#">ホーム</Breadcrumb.Item>
        <Breadcrumb.Item href="">車両保険の内容について</Breadcrumb.Item>
      </Breadcrumb>

      <Alert variant="success">レンタルバイク『ヤスカリ』の基本料金には、以下の任意保険が付帯しています。</Alert>

      <h4 className="mt-3 font-bold">任意保険</h4>
      <Table responsive hover className="mt-3 text-nowrap">
        <tbody>
          <tr>
            <th>対人</th>
            <td>無制限 1名につき</td>
          </tr>
          <tr>
            <th>対物</th>
            <td>1,000万円 1事故につき、免責5万円</td>
          </tr>
          <tr>
            <th>搭乗者傷害</th>
            <td>1名につき500万円まで(死亡後遺障害のみ)＊原付を除く</td>
          </tr>
        </tbody>
      </Table>

      <h4 className="mt-3 font-bold">オプション車両補償（免責は1事故につき）</h4>
      <p className="small text-danger">加入するか加入しないかを選ぶことができます。</p>
      <p className="small text-danger">加入しない場合は事故や転倒、破損等が発生した場合に修理代金の全額をお支払いしてただきます。</p>

      <p className="mt-3">免責とは、事故を起こした際にお客様にご負担いただく最大のお支払金額です。</p>
      <p>（事故１件毎にかかります）</p>
      <p>例えば修理代金が20万円の場合は、免責の5万円を上限としてご負担いただき、15万円分を車両補償でカバーいたします。</p>

      <Table responsive hover className="mt-3 text-nowrap">
        <tbody>
          <tr>
            <th>クラス</th>
            <th>１日</th>
            <th>１週間</th>
            <th>２週間</th>
            <th>１ヶ月</th>
          </tr>
          <tr>
            <th>50cc ～ 125cc</th>
            <td>1,650円</td>
            <td>2,200円</td>
            <td>3,300円</td>
            <td>6,600円</td>
          </tr>
          <tr>
            <th>126cc ～ 250cc</th>
            <td>2,200円</td>
            <td>4,400円</td>
            <td>5,500円</td>
            <td>11,000円</td>
          </tr>
          <tr>
            <th>251cc ～</th>
            <td>2,750円</td>
            <td>5,500円</td>
            <td>6,875円</td>
            <td>13,750円</td>
          </tr>
        </tbody>
      </Table>

      <p className="mt-3">「営業補償は車両補償加入の有無関係なしにかかります。基準は車両補償の免責内容と同じです。</p>
      <p className="mt-3"><span className="font-bold">自走可能</span>（破損超軽微。問題なく乗れるキズ等）の場合　<span className="font-bold">免責2万円</span></p>
      <p><span className="font-bold">自走不可能</span>（破損。またはレッカーを使用した場合　<span className="font-bold">免責5万円</span></p>

      <h4 className="mt-3 font-bold">オプション盗難補償</h4>
      <p className="small text-danger">加入するか加入しないかを選ぶことができます。</p>
      <p>加入すると、バイクが盗難にあった場合、オートバイの時価額の５０％を補償します。</p>
      <p className="small text-danger">オプション未加入の場合は盗難の際、オートバイの時価額１００％をお支払いただきます。</p>

      <Table responsive hover className="mt-3 text-nowrap">
        <tbody>
          <tr>
            <th>クラス</th>
            <th>料金</th>
            <th>遅延の場合</th>
          </tr>
          <tr>
            <th>50ccスクーター、ジャイロキャノピー</th>
            <td>1,100円</td>
            <td rowSpan={5} className="align-middle">補償対象外</td>
          </tr>
          <tr>
            <th>50ccミッション、125cc</th>
            <td>2,200円</td>
          </tr>
          <tr>
            <th>ビックスクーター、250ccミッション</th>
            <td>3,300円</td>
          </tr>
          <tr>
            <th>256〜400cc</th>
            <td>4,400円</td>
          </tr>
          <tr>
            <th>401cc〜</th>
            <td>5,500円</td>
          </tr>
        </tbody>
      </Table>

      <h4 className="mt-3 font-bold">ロードサービス</h4>
      <p>180kmまで</p>

      <Alert variant="danger" className="mt-3">
        <Alert.Heading>下記の場合、補償の適用が受けられません</Alert.Heading>
        <p>下記のような状態で事故が発生した場合は、一切の保険・補償の適用が受けられません。十分にご注意ください。</p>        
      </Alert>

      <Row>
        <Col md={8} sm={12}>
          <ListGroup>
            <ListGroup.Item>無免許運転</ListGroup.Item>
            <ListGroup.Item>飲酒運転</ListGroup.Item>
            <ListGroup.Item>運転中にヘルメットを着用していない場合</ListGroup.Item>
            <ListGroup.Item>契約書記載の運転者以外の方の事故</ListGroup.Item>
            <ListGroup.Item>事故現場から警察への届け出を怠った場合（警察の事故証明が取得できない場合）</ListGroup.Item>
            <ListGroup.Item>勝手に相手側と示談した場合</ListGroup.Item>
            <ListGroup.Item>事故現場から出発店舗（または本部）への連絡を怠った場合</ListGroup.Item>
            <ListGroup.Item>貸渡時間を無断延長して事故を起こした場合</ListGroup.Item>
            <ListGroup.Item>盗難によって生じた車両損害</ListGroup.Item>
            <ListGroup.Item>定員をオーバーして走行した場合</ListGroup.Item>
            <ListGroup.Item>公道以外を走行した場合</ListGroup.Item>
            <ListGroup.Item>使用方法が劣悪なために生じた単体などの損傷や腐蝕の補修費</ListGroup.Item>
            <ListGroup.Item>各種テスト・競技への使用、または他車のけん引・後押しに使用した場合</ListGroup.Item>
            <ListGroup.Item>営業店内で他の車両や看板などを破損した場合</ListGroup.Item>
            <ListGroup.Item>操作ミスによる故障</ListGroup.Item>
            <ListGroup.Item>当社の貸渡約款の条項に違反して使用した場合</ListGroup.Item>
            <ListGroup.Item>その他保険約款の免責事項に該当する事故</ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4} sm={12}>
          <Image src="/static/images/batsu.jpg" className="mx-auto" />
        </Col>
      </Row>

    </Container>

    <Footer />
    </>
  );
};

export default Index;
