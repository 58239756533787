import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Container, Navbar, Nav, ListGroup, Card, Button, Row, Col, Breadcrumb, Table } from 'react-bootstrap'
import NumericLabel from 'react-pretty-numbers';

import moment from 'moment';
import 'moment/locale/ja';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { LogoutButton } from 'views/my/_logout_button';
import { getFetch } from 'api/fetch';
import { MyIndex as Response } from 'api/my';
import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';

export const Index = () => {
  useTitle('マイページ | 激安・便利なレンタルバイクのヤスカリ。');
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    getFetch('/my').then(res => {
      if (!!res?.error) {
        return false;
      }

      localStorage.setItem('_member_name', `${res?.my.name1} ${res?.my.name2}`);
      setResponse(res);
    });
  }, []);

  return (
    <>
    <Header />
    <Container fluid="lg">
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item active>マイページ</Breadcrumb.Item>
      </Breadcrumb>

      <Table responsive className="text-nowrap">
        <tbody>
          <tr>
            <th className="w-25">会員番号</th>
            <td>{response?.my?.id}</td>
          </tr>
          <tr>
            <th>お名前</th>
            <td>{response?.my?.name1} {response?.my?.name2}</td>
          </tr>
        </tbody>
      </Table>

      <Nav fill variant="tabs" defaultActiveKey="/my" className="my-3">
        <Nav.Item>
          <Nav.Link as={Link} to="/my" active>ご予約状況</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/my/profile">プロファイル</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/my/security">セキュリティ</Nav.Link>
        </Nav.Item>
      </Nav>

      {(response && response.reservations.length > 0) ?
        <>
        {response.reservations.map(reservation => (
          <Card className="mt-3" key={reservation.id}>
            <Card.Header><Link to={`/my/reservations/${reservation.id}`}>#{reservation.id}</Link></Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item as="li">
                店舗
                <span className="float-right"><Link to={`/shops#${reservation.shop_id}`} className="text-danger">{GetEnumsName(Enums['shops']['id'], reservation.shop_id)}</Link></span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                車種
                <span className="float-right"><Link to={`/models/${reservation.model_id}`} className="text-danger">{reservation.model_name}</Link></span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                貸出希望日時
                <span className="float-right">{moment((String(reservation.start_date)+reservation.start_time), 'YYYYMMDDHHmm').format('YYYY年MM月DD日 (ddd) HH時mm分')}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                返却日時
                <span className="float-right">{moment((String(reservation.end_date)+reservation.end_time), 'YYYYMMDDHHmm').format('YYYY年MM月DD日 (ddd) HH時mm分')}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                決済金額
                <span className="float-right"><NumericLabel>{reservation.amount}</NumericLabel>円</span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                予約状態
                <span className="float-right">{GetEnumsName(Enums['reservations']['status'], reservation.status)}</span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Button variant="success" className="bg-green-700" block as={Link} to={`/my/reservations/${reservation.id}`}>予約詳細</Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        ))}
        </>
        :
        <>
        <Card className="mt-3">
          <Card.Body>
            ご予約はありません
          </Card.Body>
        </Card>
        </>
      }

      <LogoutButton className="mt-3 bg-red-700" />
    </Container>

    <Footer />
    </>
  );
};

export default Index;
