import React from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import {
  Container,
  ListGroup,
  Card,
  Button,
  Row,
  Col,
  Badge,
  Carousel as RBC,
  Image
} from 'react-bootstrap'
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { STORAGE_URL, GetEnumsName, Enums } from 'config/const';
import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { getFetch } from 'api/fetch';
import { Index as Response } from 'api/index';

export const Home = () => {
  useTitle('激安レンタルバイク『ヤスカリ』 東京都足立区、荒川区、北区、葛飾区。地域最安値を目指します！');

  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    getFetch(`/`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
    });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <>
    <Header />
    <Container fluid="lg">
      <RBC className="mt-3">
        <RBC.Item>
          <Image className="w-100"
            src="/static/images/home/slide.jpg"
          />
        </RBC.Item>
        <RBC.Item>
          <Image className="w-100"
            src="/static/images/home/slide2.jpg"
          />
        </RBC.Item>
      </RBC>

      <Card className="mt-3 border-none">
        <Card.Header className="bg-red-700 text-white">ヤスカリ レンタルバイクの人気車種</Card.Header>
        {(response && response.models.length > 0) &&
          <Carousel responsive={responsive} ssr>
            {response.models.map(model => (
            <div key={model.id}>
              <Card className="text-center px-3 border-none d-inline">
                <Card.Img variant="top" src={`${STORAGE_URL}/models/${model.image}`} height="300" />
                <Card.Body>
                  <Card.Title>{model.name}</Card.Title>
                  <Card.Text>
                    24時間レンタル <NumericLabel>{model.class ? model.class.price_1d : 0}</NumericLabel>円
                  </Card.Text>
                  <Button variant="success" className="mt-3 stretched-link" as={Link} to={`/models/${model.id}`}>詳細を見る</Button>
                </Card.Body>
              </Card>
            </div>
            ))}
          </Carousel>
        }
      </Card>

      <Card className="mt-3">
        <Card.Header className="bg-red-700 text-white">新着情報</Card.Header>
        <ListGroup variant="flush">
          {(response && response.informations.length > 0) &&
            <>
            {response.informations.map(information => (
              <ListGroup.Item as={Link} to={`/informations/${information.id}`} className="d-flex justify-content-between align-items-start" key={information.id}>
                <div className="truncate">{information.title}</div>
                <Badge pill className="mt-1">{GetEnumsName(Enums['informations']['category'], information.category)}</Badge>
              </ListGroup.Item>
            ))}
            <ListGroup.Item as={Link} to={'/informations'} className="d-flex justify-content-center">
              <div className="text-secondary">すべて見る</div>
            </ListGroup.Item>
            </>
          }
        </ListGroup>
      </Card>

      <Card className="mt-3">
        <Card.Header className="bg-red-700 text-white">店舗ブログ</Card.Header>
        <ListGroup variant="flush">
          {(response && response.posts.length > 0) &&
            <>
            {response.posts.map(post => (
              <ListGroup.Item key={post.id}>
                <div className="media">
                  <Link to={`/posts/${post.id}`}><img src={`${STORAGE_URL}/posts/${post.image}`} width="64" className="mr-3" /></Link>
                  <div className="media-body truncate">
                    <h5 className="mt-0"><Link to={`/posts/${post.id}`}>{post.title}</Link></h5>
                    <p className="mt-3 text-muted text-sm">{moment.unix(post.public_at).format('YYYY/MM/DD HH:mm')}</p>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
            <ListGroup.Item as={Link} to={'/posts'} className="d-flex justify-content-center">
              <div className="text-secondary">すべて見る</div>
            </ListGroup.Item>
            </>
          }
        </ListGroup>
      </Card>

    </Container>

    <Footer />
    </>
  );
};

export default Home;
