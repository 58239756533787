import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import {
  Container,
  Breadcrumb,
  Alert,
  Image,
  Table,
  ListGroup,
  Row,
  Col,
  Card,
  Button,
  Form,
} from 'react-bootstrap'
import { ValidationForm, TextInput, Radio } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';

import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { postFetch } from 'api/fetch';

export const Index = () => {
  useTitle('お問い合わせ | 激安・便利なレンタルバイクのヤスカリ。');
  const history = useHistory();
  const [title, setTitle] = React.useState('会員登録について');
  const [confirm, setConfirm] = React.useState(false);
  const [formData, setFormData] = React.useState<FormData | undefined>(undefined);

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('email', values.email);
    formData.append('title', title);
    formData.append('content', values.content);
    setFormData(formData);
    setConfirm(true);
  };

  const handleSendForm = () => {
    postFetch('/inquiries', formData).then(res => {
      if (!!res?.error) {
        return false;
      }

      history.push('/inquiry/complete');
    });
  };

  return (
    <>
    <Header />
    <Container fluid="lg">

      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item>お問い合わせ</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="w-100 rounded-lg">
        <Card.Header as="h5">お問い合わせフォーム</Card.Header>
        <Card.Body>
          <ValidationForm onSubmit={handleSubmit}>
            <div className="form-group required">
              <label htmlFor="email">メールアドレス</label>
              <TextInput name="email" id="email" type="email" placeholder="メールアドレスを入力してください"
                required
                maxLength={50}
                errorMessage={{
                  type: 'メールアドレスを正しく入力してください',
                  required: 'メールアドレスを入力してください',
                }}
                disabled={confirm}
              />
            </div>

            <div className="form-group required mt-4">
              <label htmlFor="title">お問い合わせ項目</label>
              <Radio.RadioGroup
                name="title"
                id="title"
                required
                valueSelected={title}
                onChange={(e: any, value: string) => setTitle(value)}
              >
                <Radio.RadioItem id="title1" label="会員登録について" value="会員登録について" disabled={confirm} />
                <Radio.RadioItem id="title2" label="予約や日程変更について" value="予約や日程変更について" disabled={confirm} />
                <Radio.RadioItem id="title3" label="キャンセルについて" value="キャンセルについて" disabled={confirm} />
                <Radio.RadioItem id="title4" label="車両について" value="車両について" disabled={confirm} />
                <Radio.RadioItem id="title5" label="その他" value="その他" disabled={confirm} />
              </Radio.RadioGroup>
            </div>

            <div className="form-group required">
              <label htmlFor="content">お問い合わせ内容</label>
              <TextInput name="content" id="content" type="content" placeholder="お問い合わせ内容を入力してください"
                required
                multiline
                rows="5"
                errorMessage={{
                  required: 'お問い合わせ内容を入力してください',
                }}
                disabled={confirm}
              />
            </div>

            {confirm ?
              <Row>
                <Col xs={4}>
                  <Button variant="secondary" type="button" block className="my-2" size="lg" onClick={() => setConfirm(false)}>キャンセル</Button>
                </Col>
                <Col xs={8}>
                  <Button variant="primary" type="button" block className="my-2" size="lg" onClick={handleSendForm}>送信</Button>
                </Col>
              </Row>
            :
              <Button variant="primary" type="submit" block className="my-2" size="lg">確認</Button>
            }
          </ValidationForm>

        </Card.Body>
      </Card>
    </Container>

    <Footer />
    </>
  );
};

export default Index;
