import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Analytics from 'react-router-ga';

import { Index as PostsIndex } from 'views/posts/index';
import { Show as PostsShow } from 'views/posts/show';
import { Index as ModelsIndex } from 'views/models/index';
import { Show as ModelsShow } from 'views/models/show';
import { Show as ReserveShopsModelsShow } from 'views/reserve/shops/models/show';
import { Options as ReserveShopsModelsOptions } from 'views/reserve/shops/models/options';
import { Bikes as ReserveShopsModelsBikes } from 'views/reserve/shops/models/bikes';
import { Show as ReserveModelsShow } from 'views/reserve/models/show';
import { Index as ReservePaymentIndex } from 'views/reserve/payment/index';
import { Complete as ReservePaymentComplete } from 'views/reserve/payment/complete';

import { Home } from 'views/index';
import { Index as LoginIndex } from 'views/login/index';
import { Index as RegisterIndex } from 'views/register/index';
import { Auth as RegisterAuth } from 'views/register/auth';
import { Index as ShopsIndex } from 'views/shops/index';
import { Index as GuideIndex } from 'views/guide/index';
import { Index as FAQIndex } from 'views/faq/index';
import { Index as InsuranceIndex } from 'views/insurance/index';
import { Index as OptionsIndex } from 'views/options/index';
import { Privacy } from 'views/privacy';
import { Law } from 'views/law';
import { Rule } from 'views/rule';
import { Index as InformationsIndex } from 'views/informations';
import { Show as InformationsShow } from 'views/informations/show';
import { Index as InquiryIndex } from 'views/inquiry';
import { Complete as InquiryComplete } from 'views/inquiry/complete';

import { Forget as PasswordForget } from 'views/password/forget';
import { Reset as PasswordReset } from 'views/password/reset';

import { Index as MyIndex } from 'views/my';
import { Profile as MyProfile } from 'views/my/profile';
import { Security as MySecurity } from 'views/my/security';
import { Show as MyReservationsShow } from 'views/my/reservations/show';

import { ANALYTICS_ID } from 'config/const';

function App() {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Analytics id={ANALYTICS_ID}>
          <Switch>
            <Route path="/models/shops/:id" component={ModelsIndex} />
            <Route path="/models/:id" component={ModelsShow} />
            <Route path="/models" component={ModelsIndex} />
            <Route path="/posts/:id" component={PostsShow} />
            <Route path="/posts" component={PostsIndex} />
            <Route path="/reserve/shops/:sid/models/:mid/options" component={ReserveShopsModelsOptions} />
            <Route path="/reserve/shops/:sid/models/:mid/bikes" component={ReserveShopsModelsBikes} />
            <Route path="/reserve/shops/:sid/models/:mid" component={ReserveShopsModelsShow} />
            <Route path="/reserve/models/:id" component={ReserveModelsShow} />
            <Route path="/reserve/payment/complete" component={ReservePaymentComplete} />
            <Route path="/reserve/payment" component={ReservePaymentIndex} />

            <Route path="/shops" component={ShopsIndex} />

            <Route path="/option" component={OptionsIndex} />
            <Route path="/options" component={OptionsIndex} />

            <Route path="/guide" component={GuideIndex} />
            <Route path="/faq" component={FAQIndex} />
            <Route path="/insurance" component={InsuranceIndex} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/law" component={Law} />
            <Route path="/rule" component={Rule} />
            <Route path="/inquiry/complete" component={InquiryComplete} />
            <Route path="/inquiry" component={InquiryIndex} />
            <Route path="/login" component={LoginIndex} />
            <Route path="/register/auth" component={RegisterAuth} />
            <Route path="/register" component={RegisterIndex} />
            <Route path="/password/forget" component={PasswordForget} />
            <Route path="/password/reset" component={PasswordReset} />

            <Route path="/informations/:id" component={InformationsShow} />
            <Route path="/informations" component={InformationsIndex} />

            <Route path="/my/profile" component={MyProfile} />
            <Route path="/my/security" component={MySecurity} />
            <Route path="/my/reservations/:id" component={MyReservationsShow} />
            <Route path="/my" component={MyIndex} />

            <Route path="/" component={Home} />
          </Switch>
        </Analytics>
      </LastLocationProvider>
    </BrowserRouter>
  );
}

export default App;
